import React from 'react'
import { Link } from 'react-router-dom'
import GoogleMapReact from 'google-map-react';


import SectionHeader from '../reusable/SectionHeader'
import ContactUs from '../reusable/ContactUs'
import Success from '../reusable/Success';
import { Loader } from '../SvgIcon';
import {ConnectUs} from '../../actions/AuthActions'
import {connect} from "react-redux";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
      lat:28.5355,
      lng:77.3910
    },
    zoom: 11
  }
  
class Contact extends React.Component{
    constructor(props){
        super(props);
        console.log("propsvlaue",props)
        this.state = {
            name: '',
            email: '',
            infoText: '',
            phone:'',
            messageText:'',
            nameErrorMsg:'',
            emailErrorMsg:'',
            isSuccess:false,
            loaderOpen:false,
            userLocation: {  lat:28.5355,
                lng:77.3910}

        }
    }
    componentWillMount(props) {
        this.setState({
          userLocation: navigator.geolocation.getCurrentPosition(
            this.renderPosition
          )
        });
      }
      renderPosition(position) {
        return { lat: position.coords.latitude, lng: position.coords.longitude };
      }
    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        let validate = true;
        let {name, email} = this.state
        let validateFirstName = nameRegx.test(String(this.state.name).toLowerCase());
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
        //  Name Validate
    
        if ((name === '' || name === undefined)) {
            validate = false;
            this.setState({nameErrorMsg: 'Please enter name'})
        } else if (!validateFirstName) {
            validate = false;
            this.setState({nameErrorMsg: 'Please enter a valid name'})
        } else {
            this.setState({nameErrorMsg: ''})
        }
    
        // Email Validate
    
        if (email === '' || email === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter email address'})
        } else if (!emailRegex.test(email)) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }
    
        return validate
    }
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = e => {
        const {propsValue} = this.props;
        
        e.preventDefault();
        console.log(this.state)
        const {name,email,phone,infoText,messageText} = this.state;
        if (this.handleValidetion()) {
            this.setState({
                loaderOpen:true
            })

            let data ={
                'name':name,
                'email':email,
                'contactNumber':phone,
                'infoMessage':infoText,
                'message':messageText
            }
             
             this.props.ConnectUs(data).then(response=>{
                console.log("data constacts us",response)
                 if(response == 'Success'){
                    this.setState({
                        isSuccess:true,loaderOpen:false
                    })
                 }else{

                 }

             }).catch(e=>{
                 console.log(e)
             })
        }else {

        }
    }
    
     
             
    

    render() {
        document.body.classList.add('cm_home_page');
        let { email,name,emailErrorMsg,nameErrorMsg,isSuccess,loaderOpen,phone,infoText,messageText} = this.state;

        return (
            <div className="other_page">
               
                {
                    loaderOpen ? <Loader/> :null
                }
                 
                
                {!isSuccess &&
                  <div className="section contact_us_form_wrapper">
               
                   
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-lg-3 offset-lg-1 col-md-4 addressparent">
                        <img className="img-fluid sml-img" src={require('../../assets/login-bg.png')} style={{width:'130px'}} />
                          <p className="address">
                            EkVichaar.org<br/>
                            A-152, 2nd floor, <br/>Sector-63, Noida- 201307<br/>
                            Mobile- +91 9654838680
                          </p>
                        </div>

                        <div className="col-lg-5 col-md-6 offset-lg-1">
                        <form onSubmit={this.handleSubmit}>
                        <h3>Let’s Talk for Cooperation!</h3>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="name">Name*</label>
                                <input type="text"
                                       className="form-control"
                                       id="name"
                                       name="name"
                                       value={name}
                                       maxLength={25}
                                       placeholder="Enter name"
                                       onChange={this.handleChange} />
                                {nameErrorMsg ?<div class="input_error_msg">{nameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="email">Email*</label>
                                <input type="email"
                                       className="form-control"
                                       id="email"
                                       name="email"
                                       value={email}
                                       maxLength={50}
                                       placeholder="email@domain.com"
                                       onChange={this.handleChange} />
                                {emailErrorMsg ? <div class="input_error_msg">{emailErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="number">Contact Number</label>
                                <input type="number" className="form-control" id="number" name="phone" placeholder="Enter contact number"
                                 value={phone} onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">How did you hear about us?</label>
                                <input type="text" className="form-control" id="infoText" name="infoText" 
                                placeholder="How did you hear about us?"    maxLength={25}
                                value={infoText} onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-12">
                                <label htmlFor="message">Message</label>
                                <textarea class="form-control" id="message" name="messageText" value={messageText} 
                                placeholder="Enter your message here"    maxLength={100}
                                rows="3" onChange={this.handleChange}></textarea>
                            </div>

                            <div className="form-group align-self-center col-md-7">
                                <div className="row">
                                    {/* <div className="col-8 align-self-center">Captcha, What is 3 + 5 =</div>
                                    <div className="col-4 align-self-center"><input type="text" className="form-control" id="captcha" name="captcha" placeholder="Answer" /></div> */}
                                </div>
                            </div>
                            <div className="form-group align-self-center col-md-5 text-right">
                                <button className="btn btn-primary">Send</button>
                            </div>

                        </div>
                        </form>
                        </div>

                       </div>
                    </div>
                </div>
                }
                {isSuccess &&   <Success btnTitle={'Go To Home'} 
                        title={'Kudos to your thought, you are one step closer for being a part of this beautiful journey.Our team member will contact you soon!'}
                         btnAction={()=>this.props.history.push('/')}/>
                
                }
           
                {/* <ContactUs propsValue={this.props}/> */}
             </div>
        )
    }
}
const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{ConnectUs})(Contact);