import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Popup from "reactjs-popup";
import {ForgotPass} from '../../actions/AuthActions'
import {connect} from "react-redux";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Success from '../reusable/Success';
import { Loader } from '../SvgIcon';


class ForgotPassword extends Component {

    constructor(props){
        super(props)

        this.state ={
            email: '',
            password: '',
            emailErrorMsg:'',
            passwordErrorMsg:'',
            openPopup: false,
            isSuccess:false,
            loaderOpen:false

        }
        
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleValidetion = () => {

        let validate = true;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        const {email, password} = this.state;
        let validateEmail = regx.test(String(email).toLowerCase());
        let validatePassword = password;


        // Email Validate

        if (!email) {
            validate = false;
            this.setState({emailErrorMsg: 'Email is required'})
        } else if (!validateEmail) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }

        return validate
    }
   
    closeModal = () =>{this.setState({openPopup: false});}


   
    createNotification = (type) => {
       
          switch (type) {
            case 'info':
              NotificationManager.info('Info message');
              break;
            case 'success':
                NotificationManager.success("Password reset link has been sent on your email.");
              break;
            case 'warning':
              NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
              break;
            case 'error':
                NotificationManager.error('User Not Found!');
                //this.setState({email:''})
              break;
          }
        };
      
        
    
        
    


    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state)
        const {email} = this.state;
        if (this.handleValidetion()) {
            this.setState({
                loaderOpen:true
            })
           let data={
                'email':email
            }
         this.props.ForgotPass(data).then(response => {
                console.log(response,"lognresponse")
                 if (response =="Success") {
                     this.setState({
                        isSuccess:true,loaderOpen:false
                     })
                }else {
                     this.createNotification('error')
                     this.setState({
                        loaderOpen:false
                    })
                 }
             }).catch(e => {
         })
            
            
        }else {

        }
    }
    

    render() {
        document.body.classList.add('cm_home_page');
        let { email, password, emailErrorMsg,passwordErrorMsg,isSuccess,loaderOpen } = this.state;
        return(
            <div className="other_page fix-footer">
                    <NotificationContainer/>

                    { loaderOpen ? <Loader/> :null}

                    {!isSuccess  &&     

                <div className="container pt-5 mb-5">
                    <h3>Forgot your password!</h3>
                    <div className="row">
                        <div className="col-md-8">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Email*</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="email@domain.com"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                    />
                                    {emailErrorMsg ? <div class="input_error_msg">{emailErrorMsg}</div> : null}
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                       
                        <div className="col-md-4">
                            <img className="img-fluid" src={require('../../assets/login-bg.png')} />
                        </div>
                    </div>
                </div>}
                {isSuccess &&               
                  <Success btnTitle={'Go To Login'} 
                  title={'Reset Password link  has been send in your registered email.'}
                  btnAction={()=>this.props.history.push('/login')}/>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{ForgotPass})(ForgotPassword);

