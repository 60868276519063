import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Success from '../reusable/Success';
import {connect} from "react-redux";
import {Join_Us} from '../../actions/AuthActions';

class JoinUs extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            montsNames: ['Jan','feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            days: [],
            fName: '',
            lName: '',
            email: '',
            address: '',
            phoneNumber: '',
            profession: '',
            gender: '',
            city:'',
            month: '',
            day: '',
            year: '',
            password: '',
            message:'',
            passwordAgain: '',
            fNameErrorMsg:'',
            lNameErrorMsg:'',
            emailErrorMsg:'',
            passwordErrorMsg:'',
            passwordAgainErrorMsg:'',
            contactErrorMsg:'',
            userImage: require('../../assets/user_placeholder.png'),
            startDate:'',
            successMessage:'Team Ek Vichaar Welcome you in our family!. Thanks for registration.',
            isSuccess:false
        }
        
    }
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
       // this.handleValidetion()
           
    }


   

    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        let validate = true;
        let {fName, lName, email,password,contactErrorMsg,passwordAgain,phoneNumber} = this.state
        let validateFirstName = nameRegx.test(String(this.state.fName).toLowerCase());
        let validateLastName = nameRegx.test(String(this.state.lName).toLowerCase());
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        // First Name Validate

        if ((fName === '' || fName === undefined)) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter first name'})
        } else if (!validateFirstName) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter a valid first name'})
        } else {
            this.setState({fNameErrorMsg: ''})
        }


        // Last Name Validate

        if (lName === '' || lName === undefined) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter last name'})
        } else if (!validateLastName) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter valid last name'})
        } else {
            this.setState({lNameErrorMsg: ''})
        }

        // Email Validate

        if (email === '' || email === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter email address'})
        } else if (!emailRegex.test(email)) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }

        return validate
    }
    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state,"join us state")
        const {fName,lName,email,profession,address,city,gender,message,phoneNumber,startDate} = this.state;
        if (this.handleValidetion()) {

            let data = {
                'firstName':fName,
                'lastName':lName,
                'email':email,
                'contactNumber':phoneNumber,
                'gender':gender == 'male'?1:2,
                'dob':startDate,
                'profession':profession,
                'address':address,
                'city':city,
                'message':message
                       
            }
            console.log("datatatat",data)
            this.props.Join_Us(data).then(res=>{
                console.log("resss",res.data.statusCode)
                if(res.data.statusCode ===1){
                    this.setState({
                        isSuccess:true
                    })
                }

            }).catch(e=>{
                console.log(e);
            })
     } else {

        }
    }
    handleDatePicker = e => this.setState({startDate: e})
   

    render(){

        let {
            montsNames,
            days,
            fName,
            lName,
            email,
            address,
            phoneNumber,
            profession,
            gender,
            fNameErrorMsg,
            lNameErrorMsg,
            emailErrorMsg,
            passwordErrorMsg,
            contactErrorMsg,
            month,
            day,
            year,
            password,
            passwordAgain,
            passwordAgainErrorMsg,
            userImage,
            startDate,
            city,isSuccess,
            message
        } = this.state;

        var cuurentDate = new Date()
        var minDate = new Date(cuurentDate.getFullYear() - 100, 0, 1)
        var maxDate = new Date(cuurentDate.getFullYear() -10, cuurentDate.getMonth(), cuurentDate.getDate())
        document.body.classList.add('cm_home_page');
       
        return(
            <div className="other_page">
                {!isSuccess &&
                <form onSubmit={this.handleSubmit} className="pt-5 mb-5">
                    <div className="container">
                        <h3>Join Us!</h3>
                        <br />
                        <div className="row">

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">First Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fName"
                                    name="fName" 
                                    placeholder="Enter first name"
                                    value={fName}
                                    onChange={this.handleChange}   
                                />
                                 {fNameErrorMsg ?
                                    <div class="input_error_msg">{fNameErrorMsg}</div> : null}
                                
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Last Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lName"
                                    name="lName"
                                    value={lName}
                                    placeholder="Enter last name"
                                    onChange={this.handleChange}  
                                />
                                 {lNameErrorMsg ?
                                    <div class="input_error_msg">{lNameErrorMsg}</div> : null}
                              
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="email">Email*</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="email@website.com"
                                    value={email}
                                    onChange={this.handleChange} 
                                   
                                />
                                 {emailErrorMsg ?
                                    <div class="input_error_msg">{emailErrorMsg}</div> : null}
                               
                            </div>


                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="number">Contact Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    placeholder="Enter contact number"
                                    onChange={this.handleChange} 
                                />
                                
                                    
                            </div>
                       


                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="gender">Gender</label>
                                    <div className="cm_space">
                                    <span className="cm_radio_wrapper">
                                        Male
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="male"
                                            onChange={this.handleChange} 
                                           
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>

                                    <span className="cm_radio_wrapper">
                                    Female
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="female"
                                            onChange={this.handleChange} 
                                           
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>

                                    <span className="cm_radio_wrapper">
                                    Other
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="other"
                                            onChange={this.handleChange} 
                                            
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>
                                    </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession" className="d-block">Date of Birth</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={this.handleDatePicker}
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    dateFormat="dd/MM/yyyy"
                                    scrollableYearDropdown
                                    placeholderText="DD/MM/YYYY"
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    yearDropdownItemNumber={50}
                                    className="form-control d-block"
                                    name="startDate"
                                />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession"> placeholder</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="profession"
                                    name="profession"
                                    value={profession}
                                    placeholder="Enter profession "
                                    onChange={this.handleChange}  
                                   
                                />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession">Full Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fullAddress"
                                    name="address"
                                    placeholder="Enter address "
                                    
                                    value={address}
                                    onChange={this.handleChange}  
                                   
                                />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession">City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cityAndState"
                                    name="city"
                                    placeholder="Enter city "
                                    value={city}
                                    onChange={this.handleChange} 
                                />
                            </div>


                            <div className="form-group col-12">
                            <label htmlFor="profession">Why do you want to join Ek Vichaar?</label>
                                <textarea
                                    type="text"
                                    className="form-control join_us_area"
                                    id="message"
                                    name="message"
                                    value={message}
                                    placeholder="Enter your message "
                                    onChange={this.handleChange} 
                                   
                                ></textarea>
                            </div>
                          

                            <div className="form-group col text-center">
                                <br/>

                                <button type="submit" className="btn btn-primary">Join Us</button>
                               
                            </div>
                        </div>
                    </div>
                </form>}
                {isSuccess &&   <Success btnTitle={'Go To Home'} 
                        title={'Kudos to your thought, you are one step closer for being a part of this beautiful journey.Our team member will contact you soon!'}
                         btnAction={()=>this.props.history.push('/')}/>
                
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log("123456",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{Join_Us})(JoinUs);

