import {loginUser,registerUser, userDetails,userLogout,forgotPassword,changePass,updateProfile,
    projectList,donateProducts,createProject,deleteProjects,updateProject,connectUs, totalDonation,donators,
    uploadProjectImages,getMemberList,join_us,donate_money,resetPassword, image_gallery} from "../config/services/AuthServices";
import {LOGIN_FAILURE, LOGIN_SUCCESS} from "./Types";

export const LoginAction =(obj)=> {
    console.log('login response', obj)
    
    return (dispatch) => {
        
        return loginUser(obj).then( res => {
                console.log('login response', res)
                if (res.data.statusCode === 1) {
                    console.log('login response0000', res.data.responseData.accessToken)
                    localStorage.setItem('accessToken', res.data.responseData.accessToken);
                    dispatch({type: LOGIN_SUCCESS, payload:res.data.responseData});
                    return res;
                }else {
                    dispatch({type: LOGIN_FAILURE, payload: 'false'});
                    return res;
                }
             }).catch(e => {
            console.log(e)
            return "failed";
        })
    };
}
export const SignupAction =(obj)=> {
    console.log('signup response', obj)
    return (dispatch) => {
        return registerUser(obj).then( res => {
            console.log('signup response', res)
            if (res.data.statusCode === 1) {
                localStorage.setItem('signup accessToken', res.data.responseData.accessToken);
                 //dispatch({type: LOGIN_SUCCESS, payload:res.data.responseData});
                return "Success";
            }else {
                //dispatch({type: LOGIN_FAILURE, payload: 'false'});
                return res;
            }
        }).catch(e => {
            console.log(e)
            dispatch({type: LOGIN_FAILURE, payload: 'false'});
            return "failed";
        })
    };
}

export const UserInfoAction=(data,token)=>{
    console.log(typeof data,"token nn",data)
    return (dispatch) => {
        return userDetails(data,token).then( res => {
            console.log('user details response', res)
            if (res.data.statusCode === 1) {
                localStorage.setItem('user details accessToken', res.data.responseData.accessToken);
                console.log("user details",res.data)
                return res.data;
            }else {
                return res;
            }
        }).catch(e => {
            console.log(e)
            return "failed";
        })
    };

}

export const UserLogout=(token)=>{
    return (dispatch)=>{
        return userLogout(token).then(res =>{
            console.log("user logout response",res)
            if(res.statusCode === 1){
                return "Success";
            }else{
                return res;
            }

        }).catch(e =>{
            console.log(e)
            return "failed";
        })
    }
}

export const ForgotPass=(data)=>{
    return(dispatch)=>{
        console.log(data,'inside action')
        return forgotPassword(data).then(res =>{
            console.log("user logout response",res.data.statusCode)
            if(res.data.statusCode === 1){
                return "Success";
            }else{
                return res;
            }

        }).catch(e =>{
            console.log(e)
            return "failed";
        })
    }
}
export const Password_Reset=(data,token)=>{
    console.log("1111111",data ,"-----",token)
    return(dispatch)=>{
        return resetPassword(data,token).then(res=>{

            console.log("reset res",res)
            if(res.data.statusCode === 1){
                return "Success";
            }else{
                return res;
            }
        }).catch(e=>{
            console.log(e)
        })
    }
}
export const UpdatePassword=(data,token)=>{
    return(dispatch)=>{
        console.log(data,'inside action')
        return changePass(data,token).then(res =>{
            console.log("user logout response",res)
            if(res.data.statusCode === 1){
                return "Success";
            }else{
                return res;
            }

        }).catch(e =>{
            console.log(e)
            return "failed";
        })
    }
}

export const UpdateProfile=(data,token)=>{
    return(dispatch)=>{
        console.log(data,"update profile",token);
        return updateProfile(data,token).then(res=>{
            console.log("update profile response",res);
            console.log("update profile response",res.data.statusCode);
            if(res.data.statusCode === 1){
                return "Success";
            }else{
                return res
            }

        }).catch(e=>{
            console.log(e)
            return "failed";
        })
    }
}

export const CreateProject=(data)=>{

    return(dispatch)=>{
        //console.log(data,"update profile",token);
        return createProject (data).then(res=>{
            console.log("update profile response",res.data.responseData);
            console.log("update profile response",res.data.statusCode);
            if(res.data.statusCode === 1){
                return "Success";
            }else{
                return res
            }

        }).catch(e=>{
            console.log(e)
            return "failed";
        })
    }

}

export const GetProjectLists=(data)=>{
    return (dispatch)=>{
        console.log("project list status",data);
        return projectList(data).then(res=>{
            console.log("project list response",res);
            console.log("project list response",res.data.statusCode);
            if(res.data.statusCode === 1){
                return res.data.responseData
            }else{
                return res
            }

        }).catch(e=>{
            console.log(e)
            return "failed";
        })
    }

}

export const DonateProducts=(data)=>{
    return(dispatch)=>{
        return donateProducts(data).then(res=>{
            console.log(res.data.statusCode,"statuscode")
            if(res.data.statusCode === 1){
                return "Success"
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })
    }
}

export const DeleteProjects=(data,token)=>{
    return(dispatch)=>{

        return deleteProjects(data,token).then(res=>{
            console.log(res.data.statusCode,"response ")
            if(res.data.statusCode === 1){
                return "Success"
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })

    }
}

export const UpdateProject=(data,token)=>{
    return (dispatch)=>{
        return updateProject(data,token).then(res=>{
            console.log(res.data.statusCode,"response ")
            if(res.data.statusCode === 1){
                return "Success"
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })

    }
}

export const ConnectUs=(data)=>{
    return(dispatch)=>{
        return connectUs(data).then(res=>{
            console.log(res.data.statusCode,"res")
            if(res.data.statusCode === 1){
                return "Success"
            }else{
                return res;
            }
        }).catch(e=>{
            console.log(e)
        })
    }
}
export const AddProjectPhotos=(data)=>{
   
    return(dispatch)=>{
        return uploadProjectImages(data).then(res=>{
           if(res.data.statusCode === 1){
                return res
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })
    }


}

export const MemberLists=(data)=>{
    return(dispatch)=>{
        return getMemberList(data).then(res=>{
            if(res.data.statusCode === 1){
                return res
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })
    }

}

export const Join_Us=(data)=>{
    return(dispatch)=>{
        return join_us(data).then(res=>{
            if(res.data.statusCode === 1){
                return res
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })
    }

}

export const Donated_Money=(data)=>{
   return(dispatch)=>{
        return donate_money(data).then(res=>{
            if(res.data.statusCode === 1){
                return res
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })
   }
}

export const Gallery_Images=(data)=>{

    console.log("abcdef",data)
   
    return(dispatch)=>{
        return image_gallery().then(res=>{
            console.log(res,78787787)
            if(res.data.statusCode === 1){
                return res
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })
   }
}

export const Total_Donation=()=>{
    return(dispatch)=>{
        return totalDonation().then(res=>{
            console.log(res,78787787)
            if(res.data.statusCode === 1){
                return res.data.responseData
            }else{
                return res;
            }

        }).catch(e=>{
            console.log(e)
        })
   }
}

export const Donator_Lists=()=>{
    return(dispatch)=>{
        return donators().then(res=>{
            console.log(res.data.statusCode,"listsss")
            if(res.data.statusCode === 1){
                return res.data.responseData
            }else{
                return res;
            }
            

        }).catch(e=>{
            console.log(e)
        })
    }
}




