import axios from 'axios';
import API from '../Api'


const accessToken = localStorage.getItem('accessToken');

export function loginUser(params) {
    return axios.post(API.USER_LOGIN,params)  
}
export function registerUser(params) {

   // return axios.post(API.USER_REGISTER,params);
    return axios({
        method: 'post',
        url:API.USER_REGISTER,
        data: params,
        headers: {
            'Authorization': API.AUTH,
       }
    })
}

export function userDetails(params,token){

    console.log("params",params,token)
    return axios({
        method: 'post',
        url: API.USER_PROFILE,
        headers: {
            'Authorization': API.AUTH,
            'Content-Type':'application/json',
            'accessToken':token
       }
    })
}

export function userLogout(token){
    console.log(token,"111111111")
   
    return axios({
        method: 'post',
        url: API.USER_LOGOUT,
        headers: {
            'Authorization': API.AUTH,
            'accessToken':token
       }
    })
}

export function forgotPassword(params){
    console.log(params,"forgot password")
    return axios.post(API.USER_FORGOT_PASSWORD,params)
}

export function resetPassword(params,token){

    console.log("reset",params,"-----",token)
    
    return axios({
        method:'post',
        url:API.USER_RESET_PASSWORD,
        data:params,
        headers:{
            'accessToken': token
        }
    })
   // return axios.post(API.USER_RESET_PASSWORD,params)
}

export function changePass(params,token){
    console.log(params,"changePass")
    return axios.post(API.USER_CHANGE_PASSWORD,params)
}

export function updateProfile(params){
    console.log("service of profile", params ,"--",);
    return axios.post(API.USER_EDITPROFILE,params)
}

export function getMemberList(param){
    return axios.post(API.MEMBER_LISTS,param)

}

export function projectList(params){
    console.log("service of profile", params );
    return axios.post(API.GET_PROJECT,params)
}

export function donateProducts(params){
    console.log("donate productss", params );
    return axios.post(API.DONATE_PRODUCTS,params)
}

export function createProject(params,token){
      return axios.post(API.CREATE_PROJECT,params)
}

export function deleteProjects(params,token){
    return axios.post(API.DELETE_PROJECT,params)
}

export function updateProject(params,token){
    return axios({
        method:'post',
        url:API.UPDATE_PROJECT,
        data:params,
        headers:{
            'Authorization': API.AUTH,
            'accessToken': token
        }
    })
}

export function connectUs(params){
     return axios.post(API.USER_CONATCTUS,params)
}

export function uploadProjectImages(params){
    return axios.post(API.UPLOAD_PROJECT_IMAGES,params)
}

export function join_us(param){
    return axios.post(API.JOIN_US,param)
}

export function donate_money(param){
    return axios.post(API.DONATE_MONEY,param)
}

export function image_gallery(){
   return axios({ method:'GET', url:API.GALLERY_IMAGES})
}

export function totalDonation(){
    return axios({method:'GET',url:API.TOTAL_DONATION})
    
}

export function donators(){
    return axios({method:'GET',url:API.DONATOR_LIST})
}

