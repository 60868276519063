import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

class Page404 extends Component {
    render() {
        document.body.classList.add('cm_home_page');
        return (
            <div className="other_page fix-footer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <div className="mt-5 text-center">
                           
                               <br/><br/>
                                <h2 style={{color: '#000'}}>
                                    Sorry, this page doesn't exist. Please check the URL or go back a page
                                </h2>

                                <br />

                                {/*<h1 className="float-left display-3 mr-4">404</h1>*/}
                                {/*<h4 className="pt-3">Oops!</h4>*/}

                                <h3 className="text-muted">404 Error. Page Not Found.</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page404;
