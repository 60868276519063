import React from 'react'

const OurImpact = (props) => {
    console.log("1212121",props)
    return(
        <div className="impact_wrapper">
            
            <div className="container">
                <h3>OUR IMPACT 2018 - 2019</h3>
                <div className="row">

                    <div className="col-md-3">
                        <img src={require('../../../assets/school-icon.png')} />
                        <h4>{props.history}</h4>
                        <h6>Projects</h6>
                    </div>

                    <div className="col-md-3">
                        <img src={require('../../../assets/cities-icon.png')} />
                        <h4>{props.history}</h4>
                        <h6>School Adopted</h6>
                    </div>

                    <div className="col-md-3">
                        <img src={require('../../../assets/donation-icon.png')} />
                        <h4>{props.donation}</h4>
                        <h6>Donations</h6>
                    </div>

                    <div className="col-md-3">
                        <img src={require('../../../assets/members-icon.png')} />
                        <h4>{props.member}</h4>
                        <h6>Members</h6>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OurImpact;