
import React from 'react'
import ReactDOM from 'react-dom'
import ScrollToTop from 'react-router-scroll-top'
import { BrowserRouter as Router } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'react-notifications/lib/notifications.css';
import 'react-dropzone-uploader/dist/styles.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ClearCache from "react-clear-cache";

import './index.css'
import App from './App'

if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'production'||process.env.REACT_APP_ENV === 'prod1') {
    // ReactGA.initialize('UA-175588236-1');
    // ReactGA.pageview(window.location.pathname + window.location.search);
    // //
    // hotjar.initialize(1967304, 6);
    console.log = function () {}
}

ReactDOM.render(
    <ClearCache auto={true}>
        {({ isLatestVersion }) => (
            <>
                {console.log( "isLatestVersion # ",isLatestVersion,localStorage.getItem('APP_VERSION'))}
                <Router>
                    <ScrollToTop>
                        <App />
                    </ScrollToTop>
                 </Router>
            </>
        )}
    </ClearCache>
    , document.getElementById('root'));

