import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import {SignupAction} from '../../actions/AuthActions'
import {connect} from "react-redux";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Loader } from '../SvgIcon';

class Registration extends Component {
    constructor(props){
        super(props);
        this.state = {
            montsNames: ['Jan','feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            days: [],
            fName: '',
            lName: '',
            email: '',
            address: '',
            phoneNumber: '',
            profession: '',
            gender: '',
            month: '',
            day: '',
            year: '',
            password: '',
            passwordAgain: '',
            fNameErrorMsg:'',
            lNameErrorMsg:'',
            emailErrorMsg:'',
            passwordErrorMsg:'',
            passwordAgainErrorMsg:'',
            contactErrorMsg:'',
            userImage: require('../../assets/user_placeholder.png'),
            uploadProfilePic:'',
            startDate:'',
            isSuccess:false,
            loaderOpen:false
        }
        this.suucessBtnAction=this.suucessBtnAction.bind(this);
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
         })
         //this.handleValidetion()
    }


    handleUserImageUpload = event => {
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.addEventListener("load", () => {
            this.setState({userImage: reader.result})
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }  
         this.setState({uploadProfilePic:file},function(){
            console.log("pathhhh",this.state.uploadProfilePic,"---",file)

        })
        

    }

    

    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        let validate = true;
        let {fName, lName, email,password,contactErrorMsg,passwordAgain,phoneNumber} = this.state
        let validateFirstName = nameRegx.test(String(this.state.fName).toLowerCase());
        let validateLastName = nameRegx.test(String(this.state.lName).toLowerCase());
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        // First Name Validate

        if ((fName === '' || fName === undefined)) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter first name'})
        } else if (!validateFirstName) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter a valid first name'})
        } else {
            this.setState({fNameErrorMsg: ''})
        }


        // Last Name Validate

        if (lName === '' || lName === undefined) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter last name'})
        } else if (!validateLastName) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter valid last name'})
        } else {
            this.setState({lNameErrorMsg: ''})
        }

        // Email Validate

        if (email === '' || email === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter email address'})
        } else if (!emailRegex.test(email)) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }

        // Password Validate
        if ((password === '' || password === undefined)) {
            validate = false;
            this.setState({passwordErrorMsg: 'Please enter password'})
        } else if ((password.length < 5)) {
            validate = false;
            this.setState({passwordErrorMsg: 'Minimum 6 letter password require'})
        } else {
            this.setState({passwordErrorMsg: ''})
        }

       

        // newPassword Validate

      if ((passwordAgain === '' || passwordAgain === undefined)) {
        validate = false;
        this.setState({passwordAgainErrorMsg: 'Please enter password again.'})
    } else if((password != passwordAgain)) {
        validate = false;
        this.setState({passwordAgainErrorMsg: 'New Password & Password again are not same.'})

    }else{
        this.setState({passwordAgainErrorMsg: ''})
       
    }

         //phone validate
        
         if(phoneNumber === '' || phoneNumber === undefined){
             console.log("conatct namuber")
            validate = false;
            this.setState({contactErrorMsg: 'Please enter contact number'})
         }else if ((phoneNumber.length < 10)){
            validate = false;
            this.setState({contactErrorMsg: 'Enter a valid contact number'})
        }else{
            this.setState({contactErrorMsg:''})
        }
         return validate
    }
    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state,"signup state")
        let date = new Date(this.state.startDate)
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let dob = dd +"-"+mm+"-"+yyyy
        let genderData = this.state.gender === 'male'? 1 : this.state.gender === 'female'? 2 : 3
        console.log("birthdate",dob,"genderData",genderData)
        const {email,password,passwordAgain,fName,lName,gender,address,uploadProfilePic,profession,phoneNumber,startDate} = this.state;     
        
        if (this.handleValidetion()) {

            this.setState({loaderOpen:true})
            let object = new FormData()
            object.append('deviceId', (Math.floor(900000 * Math.random()) + 100000).toString());
            object.append('deviceToken', (Math.floor(900000 * Math.random()) + 100000).toString());
            object.append('platform','3');
            object.append('password',password);
            object.append('typePasswordAgain',passwordAgain);
            object.append('email',email);
            object.append('firstName', fName);
            object.append('lastName', lName);
            object.append('gender',genderData);
            object.append('address', address);
            object.append('profession', profession);
            object.append('contactNumber',phoneNumber);
            object.append('dob', startDate);
            object.append('profilePic',uploadProfilePic)
           
            console.log("data",object)
            this.props.SignupAction(object).then(response => {
                console.log(response,"signup response")
                 if (response =="Success") {
                    this.setState({
                        isSuccess:true,loaderOpen:false
                    })
                 }else if (response.data.error.errorCode == 10){
                    this.createNotification('error') 
                    this.setState({
                        loaderOpen:false
                    })
                 }
             }).catch(e => {
             this.setState({loaderOpen: false, responseError: "Something went wrong"})
         })

       
         } else {

        }
    }
    createNotification = (type) => {
        console.log("ggggg")
        switch (type) {
            case 'info':
              NotificationManager.info('Info message');
              break;
            case 'success':
              NotificationManager.success('Success message', 'Title here');
              break;
            case 'warning':
              NotificationManager.warning('Invalid email or password');
              break;
            case 'error':
              NotificationManager.error('Email already exist. Please use another email.');
              break;
          }
        
      };
    handleDatePicker = e => this.setState({startDate: e})

    suucessBtnAction=()=>{
        console.log("btn action");
    }

    render(){
        //console.log(this.state);
        document.body.classList.add('cm_home_page');

        let {
            montsNames,
            days,
            fName,
            lName,
            email,
            address,
            phoneNumber,
            profession,
            gender,
            fNameErrorMsg,
            lNameErrorMsg,
            emailErrorMsg,
            passwordErrorMsg,
            contactErrorMsg,
            month,
            day,
            year,
            loaderOpen,
            password,
            passwordAgain,
            passwordAgainErrorMsg,
            userImage,
            startDate
        } = this.state;

        var cuurentDate = new Date()
        var minDate = new Date(cuurentDate.getFullYear() - 100, 0, 1)
        var maxDate = new Date(cuurentDate.getFullYear() -10, cuurentDate.getMonth(), cuurentDate.getDate())
        return(
            <div className="other_page">
                 <NotificationContainer/>
        { loaderOpen && <Loader/> }
                {!this.state.isSuccess &&
                <form onSubmit={this.handleSubmit} className="pt-5 mb-5">
                    <div className="container">
                        <h3>Let’s Talk for Cooperation!</h3>
                        <br />
                        <div className="row">

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">First Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fName"
                                    name="fName"
                                    value={fName}
                                    maxLength={25}
                                    placeholder="Enter first name"
                                    onChange={this.handleChange}    
                                />
                                {fNameErrorMsg ?
                                    <div class="input_error_msg">{fNameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Last Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lName"
                                    name="lName"
                                    maxLength={25}
                                    value={lName}
                                    placeholder="Enter last name"
                                    onChange={this.handleChange} 

                                />
                                {lNameErrorMsg ?
                                    <div class="input_error_msg">{lNameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="email">Email*</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    maxLength={50}
                                    placeholder="email@domain.com"
                                    value={email}
                                    onChange={this.handleChange} 
                                />
                                {emailErrorMsg ?
                                    <div class="input_error_msg">{emailErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-lg-8 col-md-6">
                                <label htmlFor="address">Full Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    name="address"
                                    maxLength={45}
                                    placeholder="Enter address"
                                    value={address}
                                    onChange={this.handleChange}
                                />

                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="number">Contact Number*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    maxLength={10}
                                    placeholder="Enter contact number"
                                    value={phoneNumber}
                                    onChange={this.handleChange}
                                />
                                     {contactErrorMsg ?
                                <div class="input_error_msg">{contactErrorMsg}</div> : null}
                            </div>
                       

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession">Profession</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="profession"
                                    maxLength={25}
                                    name="profession"
                                    placeholder="Enter profession"
                                    value={profession}
                                    onChange={this.handleChange}  
                                />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="gender">Gender</label>
                                    <div className="cm_space">
                                    <span className="cm_radio_wrapper">
                                        Male
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="male"
                                            onChange={this.handleChange}  
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>

                                    <span className="cm_radio_wrapper">
                                    Female
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="female"
                                            onChange={this.handleChange}  
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>

                                    <span className="cm_radio_wrapper">
                                    Other
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="other"
                                            onChange={this.handleChange}  
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>
                                    </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession" className="d-block">Date of Birth</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={this.handleDatePicker}
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    dateFormat="dd/MM/yyyy"
                                    scrollableYearDropdown
                                    placeholderText="DD/MM/YYYY"
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    yearDropdownItemNumber={50}
                                    className="form-control d-block"
                                    name="startDate"
                                />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession">Password*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="********"
                                    value={password}
                                    onChange={this.handleChange}  
                                />
                                {passwordErrorMsg ?
                                    <div class="input_error_msg">{passwordErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession">Type Password Again*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordAgain"
                                    name="passwordAgain"
                                    placeholder="********"
                                    value={passwordAgain}
                                    onChange={this.handleChange}  
                                />
                                {passwordAgainErrorMsg ?
                                    <div class="input_error_msg">{passwordAgainErrorMsg}</div> : null}
                            </div>
                            <div className="form-group col-lg-4 col-md-6 cm_file_upload_wrapper">
                                <span
                                    style={{backgroundImage: `url(${userImage})`}}
                                    className="file_upload_img"
                                ></span>
                                <div className="upload_file_btn">
                                    Upload Photo
                                    <input
                                        type="file"
                                        className="cm_upload_input"
                                        id="userImage"
                                        name="userImage"
                                        accept="image/jpeg,image/jpg,image/png,"
                                        onChange={this.handleUserImageUpload}  
                                    />
                                </div>
                            </div>

                            <div className="form-group col">
                                <br/>

                                <button type="submit" className="btn btn-primary">Register</button>
                                <span className="login_link">
                                    Already Registered? <Link to="/login">Login Here</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>}
                {this.state.isSuccess &&               
                     <div className="success_wrapper fix-footer">
                <div className="">
                <div className="container text-center">
                    <div className="row justify-content-center pb-2">
                        <div className="col-md-5 order-2 align-self-center">
                            <img className="success_logo_img" src={require('../../assets/successLogo.png')} />
                        </div>
                </div>
                 <div className="container">
                    <h3 className="gray_text">Team Ek Vichaar Welcome you in our family!. Thanks for registration.</h3>
                    <div className="mt-3 text-center">
                        <button type="submit" className="btn btn-primary" 
                         onClick={()=>this.props.history.push('/login')}>Go To Login</button></div>
                    </div>
                    <br/><br/>
                    </div>
                                   
                </div>
             </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{SignupAction})(Registration);

