import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SectionHeader from '../reusable/SectionHeader'
import Success from '../reusable/Success';
import {connect} from 'react-redux'
import {GetProjectLists,DonateProducts} from '../../actions/AuthActions'
import Select from "react-dropdown-select";
import { Loader } from '../SvgIcon';


class DonateProduct extends Component {
   
    constructor(props){
        super(props);
        this.state={
            fName: '',
            lName: '',
            email: '',
            fNameErrorMsg:'',
            lNameErrorMsg:'',
            emailErrorMsg:'',
            projectErrorMsg:'',
            projectEmptyCheck:'',
            productEmptyCheck:'',
            address:'',
            pinCode:'',
            isSuccess:false,
            projectList:[],
            product:'',
            productDetails:'',
            message:'',
            selectValue:'',
            isAnonymous:false,
            project:'',
            loaderOpen:false

        }
    }
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    toggleChange = () => {
        this.setState({
            isAnonymous: !this.state.isAnonymous,
        });
      }

    componentWillMount(){
        // const {projectList} = this.state

        let self = this;
        var projectList=[]
        let data ={
            'status':1
        }
        this.props.GetProjectLists(data).then(response=>{
            console.log(response[0].name,"project listsss","length",response.length)

            for (let i = 0; i < response.length; i++) {

                {response[i].isDeleted == false &&
                projectList.push({
                    name:response[i].name,
                });}
            }

            self.setState({projectList})


        }).catch(e=>{
            console.log(e)
        })
    }



   

    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        let validate = true;
        let {fName, lName, email,project,product} = this.state
        let validateFirstName = nameRegx.test(String(this.state.fName).toLowerCase());
        let validateLastName = nameRegx.test(String(this.state.lName).toLowerCase());
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        // First Name Validate

        if ((fName === '' || fName === undefined)) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter first name'})
        } else if (!validateFirstName) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter a valid first name'})
        } else {
            this.setState({fNameErrorMsg: ''})
        }


        // Last Name Validate

        if (lName === '' || lName === undefined) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter last name'})
        } else if (!validateLastName) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter valid last name'})
        } else {
            this.setState({lNameErrorMsg: ''})
        }

        // Email Validate

        if (email === '' || email === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter email address'})
        } else if (!emailRegex.test(email)) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }

        if (project === '' || project === undefined) {
            validate = false;
            this.setState({projectErrorMsg: 'Please select project'})
        
        } else {
            this.setState({projectErrorMsg: ''})
        }



        return validate
        
    }


    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state,"hhhhhh",this.state.project)
        let self = this
        
        let {fName,lName,email,address,pinCode,fNameErrorMsg,lNameErrorMsg,emailErrorMsg,
            projectList,product,productDetails,message,isAnonymous,project} = this.state;
        if (this.handleValidetion()) {
            self.setState({loaderOpen:true})
            let data={
                'donationProducts':product,
                'aboutProduct':productDetails,
                'projectName':project,
                'message':message,
                'firstName':fName,
                'lastName':lName,
                'email':email,
                'address':address,
                'postalCode':pinCode,
                'isAnonymousCheck':isAnonymous
            }
            
            this.props.DonateProducts(data).then(response => {
                console.log(response,"lognresponse")
                 if (response =="Success") {
                          self.setState({
                               isSuccess:true,loaderOpen:false
                            })
                 }else {
                    
                    
                 }
             }).catch(e => {
               //self.createNotification('error')    
               this.setState({loaderOpen: false, responseError: "Something went wrong"})
         })

     

        } else {

        }
    }

    render(){
        document.body.classList.add('cm_home_page');
        let {fName,lName,email,address,pinCode,fNameErrorMsg,lNameErrorMsg,emailErrorMsg,projectEmptyCheck,productEmptyCheck,
            projectList,product,productDetails,message,loaderOpen,projectErrorMsg} = this.state;
        return(
            <div className="other_page">

                 { loaderOpen ? <Loader/> :null}

                 {!this.state.isSuccess &&

                <form onSubmit={this.handleSubmit}  className="mb-5">
                    {/* <SectionHeader heading="Contribute Now!" pageName="Contribute Now" /> */}
                    <br/>
                    <div className="container">

                        <div className="row mb-5 mt-5">
                            <div className="col-md-4">
                                <h3>Donation Details</h3>
                            </div>
                            <div className="col-md-8">
                                <Link to="/donate" className="btn btn-secondary">Donate with Money!</Link>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4">
                                <p className="mb-0 mt-3">Donation Product</p>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="product"
                                    name="product"
                                    value={product}
                                    maxLength={50}
                                    onChange={this.handleChange} 
                                    placeholder="Enter the name of product you want to donate to Ekvichaar!"
                                />
                                 {/* {productEmptyCheck ?
                                    <div class="input_error_msg">{productEmptyCheck}</div> : null} */}
                            </div>
                           
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4">
                                <p className="mb-0 mt-3">About of Product</p>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="productDetails"
                                    name="productDetails"
                                    maxLength={50}
                                    onChange={this.handleChange} 
                                    placeholder="Enter the name of product you want to donate to Ekvichaar!"
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4">
                            <p className="mb-0 mt-3">Select Project*</p>
                            </div>
                            <div className="col-md-8">
                              
                             <select className="form-control"  name="project"   
                                   onChange={this.handleChange} >
                                  <option>Select</option>
                                  {projectList.map(function (item, index) {
                                    return (
                                         <option value={item.name}>{item.name}</option>
                                     )})
                        }  
                    </select>
                    {projectErrorMsg ?
                        <div class="input_error_msg">{projectErrorMsg}</div> : null}
                   
                               
                            </div>
                           
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4">
                            <p className="mb-0 mt-3">Message</p>
                            </div>
                            <div className="col-md-8">
                                <textarea
                                    className="form-control"
                                    placeholder="Enter your message here"
                                    name="message"
                                    id="message"
                                    rows="8"
                                    maxLength={100}
                                    onChange={this.handleChange} 
                                    ></textarea>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4">
                            <p className="mb-0 mt-1">Anonymous Donation?</p>
                            </div>
                            <div className="col-md-8">
                                <span className="cm_checkbox_wrapper">
                                    CHECK THIS BOX TO HIDE YOUR PERSONAL INFO IN OUR DONATORS LIST
                                    <input
                                        type="checkbox"
                                        className="cm_checkbox_input"
                                        id="anonymous"
                                        name="anonymous"
                                        checked={this.state.isAnonymous}
                                        onChange={this.toggleChange}
                                    />
                                    <span className="cm_checkbox_box"></span>    
                                </span>
                            </div>
                        </div>

                        <div className="row mb-5 mt-5">
                            <div className="col">
                                <h3>Donator Details</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-4">
                                <label htmlFor="profession">First Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fName"
                                    name="fName"  
                                    placeholder="Enter first name"
                                    value={fName}
                                    maxLength={25}
                                    onChange={this.handleChange}
                                />
                                 {fNameErrorMsg ?
                                    <div class="input_error_msg">{fNameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="profession">Last Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lName"
                                    name="lName"  
                                    placeholder="Enter last name"
                                    value={lName}
                                    maxLength={25}
                                    onChange={this.handleChange}
                                />
                                 {lNameErrorMsg ?
                                    <div class="input_error_msg">{lNameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="profession">Email*</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email" 
                                    placeholder="email@domain.com"
                                    value={email}
                                    maxLength={25}
                                    onChange={this.handleChange} 
                                />
                                 {emailErrorMsg ?
                                    <div class="input_error_msg">{emailErrorMsg}</div> : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-8">
                                <label htmlFor="profession">Full Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    name="address" 
                                    placeholder="Enter address"
                                    value={address}
                                    maxLength={50}
                                    onChange={this.handleChange}  
                                />
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="profession">Postal / Pin Code</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="pinCode"
                                    name="pinCode" 
                                    placeholder="Enter pincode"
                                    value={pinCode}
                                    maxLength={10}
                                    onChange={this.handleChange} 
                                />
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary" >SUBMIT DONATION</button>
                               

                    </div>
                </form>}

                {this.state.isSuccess &&
                  <Success btnTitle={'Go To Home'} 
                  title={'Kudos to your thought, you are one step closer for being a part of this beautiful journey.Our team member will contact you soon!'}
                  btnAction={()=>this.props.history.push('/')}/>
                }

               

            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{GetProjectLists,DonateProducts})(DonateProduct);

