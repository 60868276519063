import React,{ Component } from 'react'
import SectionHeader from '../reusable/SectionHeader'
import {connect} from "react-redux";
import {Donator_Lists} from '../../actions/AuthActions'
import { Loader } from '../SvgIcon';

class Donators extends Component {
    constructor(props){
        super(props);
        this.state={
            donatorLists:[],
            loaderOpen:true
        }
    }

    componentWillMount(){
        const {donatorLists} = this.state;
         this.props.Donator_Lists().then(res=>{
             console.log(res,"0101010101")
             this.setState({donatorLists:res,loaderOpen:false});

         }).catch(e=>{
             console.log(e)
         })
    }

    listCard=(props)=>{
        console.log("123123",props)
        if(props != null){
            return props.slice(Math.max(props.length - 10, 1)).map(function(item,index){
                var date = new Date(item.created)
                console.log(item.firstName,"popop",item.isAnonymousCheck,"000000")
                return(
                    !item.isAnonymousCheck &&
                    <tr><td> {item.firstName} {item.lastName}</td><td>{item.projectName}</td>
                    <td>{item.currency} {item.amount/100}</td><td>{date.toDateString()}</td>
                    </tr>
                  )
         })
     }
      
    }

    render(){
        document.body.classList.add('cm_home_page');
        console.log(this.state.donatorLists,"-------")
        return(
            <React.Fragment>
            <div className="other_page fix-footer">
                
                {
                    this.state.loaderOpen ? <Loader/> :null
                }

                <form className="mt-5 mb-5">
                    <div className="container">
                        <br/>
                        {/* <h4>List of Donators</h4> */}

                       
                    </div>
                </form>

                <div className="container mb-5 donators_list">
                    <div className="cm_table_heading">
                        Latest Top 10 Donators 
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered text-center">
                        {this.listCard(this.state.donatorLists)}
                            
                         
                        
                     </table>
                    </div>
                  
                </div>
            </div>
              <br/>
              <br/>
              
             
            
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    console.log("home state",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{Donator_Lists})(Donators);

