import React, { Component } from 'react';
//import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import { CreateProject, AddProjectPhotos } from '../../actions/AuthActions'
import { connect } from "react-redux";
import Dropzone from 'react-dropzone-uploader'
import { Loader } from '../SvgIcon';

var media = []
//var file = []
var data = []

class AddProject extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageArray: [],
            imagePriview: [],
            file: null,
            projectName: '',
            projectDesc: '',
            projectTarget: '',
            projectMember: '',
            projectRaise: '00.00',
            projectImg: [],
            nameErrorMsg: '',
            tagetErrorMsg: '',
            raiseErrorMsg: '',
            descErrorMsg: '',
            memeberErrorMsg: '',
            token: '',
            loaderOpen: false


        }
        this.handleChange = this.handleChange.bind(this)
    }
    async componentWillMount() {
        let token = await localStorage.getItem('accessToken');
        this.setState({
            token: token
        })
    }

    // handleChange(event) {
    //     this.setState({
    //       file: URL.createObjectURL(event.target.files[0])
    //     })
    //   }
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        let validate = true;
        let { projectName, projectDesc, projectMember, projectTarget, projectRaise } = this.state
        let validateFirstName = nameRegx.test(String(this.state.name).toLowerCase());
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        //  projectName Validate

        if ((projectName === '' || projectName === undefined)) {
            validate = false;
            this.setState({ nameErrorMsg: 'Please enter project name' })
        } else {
            this.setState({ nameErrorMsg: '' })
        }

        // Email Validate

        if (projectDesc === '' || projectDesc === undefined) {
            validate = false;
            this.setState({ descErrorMsg: 'Please enter description of your project ' })
        } else {
            this.setState({ descErrorMsg: '' })
        }


        if (projectMember === '' || projectMember === undefined) {
            validate = false;
            this.setState({ memeberErrorMsg: 'Please enter number of project member ' })
        } else {
            this.setState({ memeberErrorMsg: '' })
        }

        if (projectTarget === '' || projectTarget === undefined) {
            validate = false;
            this.setState({ tagetErrorMsg: 'Please enter project targer ' })
        } else {
            this.setState({ tagetErrorMsg: '' })
        }



        return validate
    }

    // handleUploadView = e => {
    //     let reader = new FileReader();
    //     let file = e.target.files[0];

    //     var imageArray = [];


    //     if (e.target.files) {

    //         /* Get files in array form */
    //         const files = Array.from(e.target.files);

    //         /* Map each file to a promise that resolves to an array of image URI's */
    //         Promise.all(files.map(file => {
    //             return (new Promise((resolve, reject) => {
    //                 const reader = new FileReader();
    //                 reader.addEventListener('load', (ev) => {
    //                     resolve(ev.target.result);
    //                 });
    //                 reader.addEventListener('error', reject);
    //                 reader.readAsDataURL(file);
    //             }));
    //         }))
    //             .then(images => {

    //                 /* Once all promises are resolved, update state with image URI array */
    //                 this.setState({ imageArray: images })

    //             }, error => {
    //                 console.error(error);
    //             });
    //     }

    // }

    handleSubmit = e => {

        e.preventDefault();
        console.log(this.state)
        const { projectName, projectDesc, projectTarget, projectRaise, projectMember, token, projectImg } = this.state;
        if (this.handleValidetion()) {
            this.setState({ loaderOpen: true })

            console.log("data imag array", projectImg)

            let object = new FormData()
            object.append('name', projectName);
            object.append('description', projectDesc);
            object.append('target', projectTarget);
            object.append('members', projectMember);
            object.append('raised', projectRaise);
            object.append('images', projectImg);
            console.log("data2323", object)


            this.props.CreateProject(object).then(response => {
                console.log(response, "lognresponse")
                if (response == "Success") {
                    this.setState({ loaderOpen: true })
                    this.props.history.push('/')
                } else {

                }
            }).catch(e => {
            })
            console.log("data", data)

        } else {

        }
    }
    //getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    // called every time a file's `status` changes
    handleChangeStatus = ({ meta, file }, status) => {
        console.log("status", status)
        console.log("meta", meta)
        console.log("file", file)
        this.setState({loaderOpen:true})

        let data = new FormData();
        let deviceId = (Math.floor(900000 * Math.random()) + 100000).toString()
        let deviceToken = (Math.floor(900000 * Math.random()) + 100000).toString()
        const { projectImg } = this.state;
        data.append('deviceId', deviceId)
        data.append('deviceToken', deviceToken)
        data.append('platform', "3")
        data.append('profilePic', file)
        
            
                this.props.AddProjectPhotos(data).then(res => {
                    console.log(res.data.responseData,"9999999")
                    this.setState({projectImg:res.data.responseData,loaderOpen:false})
                   // projectImg.push(res.data.responseData)

                }).catch(e => {
                    console.log(e);
                })
        

    }



    render() {
        document.body.classList.add('cm_home_page');

        const { profilePicPrev, projectName, nameErrorMsg, projectDesc, tagetErrorMsg, raiseErrorMsg,
            descErrorMsg, memeberErrorMsg, projectTarget, projectRaise, projectMember, loaderOpen } = this.state;
        return (

            <React.Fragment>
                <div className="other_page"></div>
                {
                    loaderOpen ? <Loader /> : null
                }
                <div className="section contact_us_form_wrapper">
                    <form onSubmit={this.handleSubmit}>
                        <div className="container">
                            <h3>Add Project</h3>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="name">Project Name*</label>
                                    <input type="text"
                                        className="form-control"
                                        id="name"
                                        name="projectName"
                                        value={projectName}
                                        onChange={this.handleChange} />

                                    {nameErrorMsg ? <div class="input_error_msg">{nameErrorMsg}</div> : null}
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="email">Project Member*</label>
                                    <input type="text"
                                        id="member"
                                        name="projectMember"
                                        value={projectMember}
                                        onChange={this.handleChange}
                                        className="form-control"
                                    />
                                    {memeberErrorMsg ? <div class="input_error_msg">{nameErrorMsg}</div> : null}


                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="message">Project Description*</label>
                                    <textarea class="form-control"
                                        id="description"
                                        name="projectDesc"
                                        value={projectDesc}
                                        onChange={this.handleChange}
                                        rows="3"></textarea>
                                    {descErrorMsg ? <div class="input_error_msg">{descErrorMsg}</div> : null}
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="message">Project Images</label><br />
                                    <Dropzone
                                        onChangeStatus={this.handleChangeStatus}
                                        accept="image/*,audio/*,video/*" />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="number">Project Traget*</label>
                                    <input type="text" className="form-control"
                                        onChange={this.handleChange}
                                        value={projectTarget}
                                        id="number" name="projectTarget" />
                                    {tagetErrorMsg ? <div class="input_error_msg">{tagetErrorMsg}</div> : null}
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="">Project Raised*</label>
                                    <input type="text"
                                        className="form-control"
                                        value={projectRaise}
                                        onChange={this.handleChange}
                                        id="number" name="projectRaise" />
                                    {raiseErrorMsg ? <div class="input_error_msg">{raiseErrorMsg}</div> : null}
                                </div>

                                <div className="form-group col-md-12">
                                    <label htmlFor="message">Project Status</label>
                                    <textarea class="form-control" id="message" name="message" rows="3"></textarea>
                                </div>



                                <div className="form-group align-self-center col-md-12 float-right">
                                    <button className="btn btn-primary float-right">Send</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    console.log("123456", state)
    return {};
};

export default connect(mapStateToProps, { CreateProject, AddProjectPhotos })(AddProject);
