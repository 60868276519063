import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Popup from "reactjs-popup";
import {Password_Reset} from '../../actions/AuthActions'
import {connect} from "react-redux";
import {NotificationContainer, NotificationManager} from 'react-notifications';

import { Loader } from '../SvgIcon';


class ResetPassword extends Component {

    constructor(props){
        super(props)

        this.state ={
            password: '',
            confirmPassword:'',
            passwordErrorMsg:'',
            confirmPassErrorMsg:'',
            openPopup: false,
            isSuccess:false,
            loaderOpen:false

        }
        
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleValidetion = () => {

        let validate = true;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        const {confirmPassword, password} = this.state;

          // Password Validate
          if ((password === '' || password === undefined)) {
            validate = false;
            this.setState({passwordErrorMsg: 'Please enter password'})
        } else if ((password.length <= 5)) {
            validate = false;
            this.setState({passwordErrorMsg: 'Minimum 6 letter password require'})
        } else {
            this.setState({passwordErrorMsg: ''})
        }

       

        // newPassword Validate

      if ((confirmPassword === '' || confirmPassword === undefined)) {
        validate = false;
        this.setState({confirmPassErrorMsg: 'Please enter confirm password .'})
    } else if ((confirmPassword.length <= 5)) {
        validate = false;
        this.setState({confirmPassErrorMsg: 'Minimum 6 letter password require'})
    } else if((password != confirmPassword)) {
        validate = false;
        this.setState({confirmPassErrorMsg: 'New Password & Confirm Password are not same.'})

    }else{
        this.setState({confirmPassErrorMsg: ''})
       
    }
        


        // // Password Validate

        // if (!password) {
        //     validate = false;
        //     this.setState({passwordErrorMsg: 'Password is required'})
        // }else {
        //     this.setState({passwordErrorMsg: ''})
        // }

        // if(!confirmPassword){
        //     validate = false;
        //     this.setState({confirmPassErrorMsg: 'Confirm Password is required'})
        // }else{
        //     this.setState({confirmPassErrorMsg: ''})
        // }

        return validate
    }
   
    closeModal = () =>{this.setState({openPopup: false});}


   
    createNotification = (type) => {
       
          switch (type) {
            case 'info':
              NotificationManager.info('Info message');
              break;
            case 'success':
                NotificationManager.success("Password reset link has been sent on your email.");
              break;
            case 'warning':
              NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
              break;
            case 'error':
                NotificationManager.error('Please try after some time');
                //this.setState({email:''})
              break;
          }
        };
      
        
    
        
    


    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state)
        const {password} = this.state;
        let token= this.props.match.params.id;
        console.log("tokennnn",token)
        if (this.handleValidetion()) {
            this.setState({
                loaderOpen:true
            })
           let data={
               'newPassword':password
           }
         this.props.Password_Reset(data,token).then(response => {
                console.log(response,"rest response")
                 if (response =="Success") {
                     this.setState({
                       loaderOpen:false
                     })
                     this.props.history.push('/login')
                }else {
                     this.createNotification('error')
                     this.setState({
                        loaderOpen:false
                    })
                 }
             }).catch(e => {
         })
            
            
        }else {

        }
    }
    

    render() {
        document.body.classList.add('cm_home_page');
        let { email, password, confirmPassword,passwordErrorMsg,isSuccess,loaderOpen,confirmPassErrorMsg } = this.state;
        return(
            <div className="other_page fix-footer">
                    <NotificationContainer/>

                    { loaderOpen ? <Loader/> :null}

                    {!isSuccess  &&     

                <div className="container pt-5 mb-5">
                    <h3>Reset your password!</h3>
                    <br/>
                    <div className="row">
                        <div className="col-md-8">
                           
                            <form onSubmit={this.handleSubmit}>
                               
                                <div className="form-group">
                                    <label htmlFor="email">Password*</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="********"
                                        value={password}
                                        onChange={this.handleChange} 
                                    />

                               {passwordErrorMsg ?
                                    <div class="input_error_msg">{passwordErrorMsg}</div> : null}
                                    
                                   
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Confirm Password*</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="*******"
                                        value={confirmPassword}
                                        onChange={this.handleChange} 
                                    />
                                     {confirmPassErrorMsg ?
                                    <div class="input_error_msg">{confirmPassErrorMsg}</div> : null}
                                   
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                       
                        <div className="col-md-4">
                            <img className="img-fluid" src={require('../../assets/login-bg.png')} />
                        </div>
                    </div>
                </div>}
                
            </div>
        )
    }
}


const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{Password_Reset})(ResetPassword);





