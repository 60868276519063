import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {LoginAction} from '../../actions/AuthActions'
import {connect} from "react-redux";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Loader } from '../SvgIcon';

class Login extends Component {

    constructor(props){
        super(props)

        this.state ={
            email: '',
            password: '',
            emailErrorMsg:'',
            passwordErrorMsg:'',
            loaderOpen:false

        }
    }

    createNotification = (type) => {
        switch (type) {
            case 'info':
              NotificationManager.info('Info message');
              break;
            case 'success':
              NotificationManager.success('Success message', 'Title here');
              break;
            case 'warning':
              NotificationManager.error('Invalid email or password');
              break;
            case 'error':
              NotificationManager.error('User not exits');
              break;
          }
        
      };
    

     handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        //this.handleValidetion()
    }
    handleValidetion = () => {

        let validate = true;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        const {email, password} = this.state;
        let validateEmail = regx.test(String(email).toLowerCase());
        let validatePassword = password;


        // Email Validate

        if (!email) {
            validate = false;
            this.setState({emailErrorMsg: 'Email is required'})
        } else if (!validateEmail) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }

        // Password Validate

        if ((validatePassword === '' || validatePassword === undefined)) {
            validate = false;
            this.setState({passwordErrorMsg: 'Password is required'})
        } else if (validatePassword.length < 5) {
            validate = false;
            this.setState({passwordErrorMsg: 'Minimum 6 letter password require'})
        } else {
            this.setState({passwordErrorMsg: ''})
        }

        return validate
    }


    handleSubmit = e => {
        e.preventDefault();
        let self = this;
       if (this.handleValidetion()) {
           this.setState({
            loaderOpen:true
           })
            let obj = {
                'deviceId': (Math.floor(900000 * Math.random()) + 100000).toString(),
                'deviceToken': (Math.floor(900000 * Math.random()) + 100000).toString(),
                'platform': "3",
                'email': this.state.email,
                'password': this.state.password
            }
            this.props.LoginAction(obj).then(response => {
                console.log(response,"lognresponse","--",response.data.statusCode)
                if (response.data.statusCode === 1) {
                    localStorage.setItem('accessToken', response.data.responseData.accessToken);
                    this.props.history.push('/');
                 }else {
                    console.log(response.data.error.errorCode,"error res")
                     if(response.data.error.errorCode == 8){
                        self.createNotification('error') ;
                        self.setState({loaderOpen:false})
                     }else{
                        self.createNotification('warning')  
                        self.setState({loaderOpen:false})
                     }
                    
                 }
             }).catch(e => {
               self.createNotification('error')    
               this.setState({loaderOpen: false, responseError: "Something went wrong"})
         })
     } else {
         //this.setState({loaderOpen: false})
     }

          
        
    }

    render() {
        document.body.classList.add('cm_home_page');
        let { email, password, emailErrorMsg,passwordErrorMsg,loaderOpen } = this.state;
        return(
            <div className="other_page fix-footer">
                {
                    loaderOpen ? <Loader/> :null
                }
                      <NotificationContainer/>
                <div className="container pt-5 mb-5">
                    <h3>Login Here!</h3>
                    <div className="row">
                        <div className="col-md-8">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Email*</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        maxLength={40}
                                        placeholder="email@domain.com"
                                        value={email}
                                        onChange={this.handleChange} 
                                    />
                                    {emailErrorMsg ? <div class="input_error_msg">{emailErrorMsg}</div> : null}
                                </div>
                                <br/>

                                <div className="form-group">
                                    <label htmlFor="email">Password*</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="*******"
                                        maxLength={40}
                                        value={password}
                                        onChange={this.handleChange} 
                                    />
                                    {passwordErrorMsg ?
                                        <div class="input_error_msg">{passwordErrorMsg}</div> : null}
                                </div>
                                <br/>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                    <span className="login_link">
                                        <Link to="/registration">Not Registered? Register Now!</Link>
                                    </span>
                                    <span className="login_link">
                                        <Link to="/forgot-password">Forgot Password? Reset</Link>
                                    </span>

                                </div>
                              </form>
                        </div>
                        <div className="col-md-4">
                            <img className="img-fluid" src={require('../../assets/login-bg.png')} />
                        </div>
                    </div>
                </div>
           </div>
        )
    }
}
const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{LoginAction})(Login);

