import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

const Testimonial = () => {
    return(
        <div className="testimonial_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                    <Carousel
                        showThumbs={false}
                        showArrows={false}
                        autoPlay={true}
                        infiniteLoop={true}
                    >
                        <div>
                            <p>Well, I must say that this is a great initiative to support primary education. The vision of Ek Vichaar is very clear and the team is pretty much honest in their efforts. Keep your good work!</p>
                            <h3>Abhilash Sharma</h3>
                        </div>
                        <div>
                            <p>When your goals are realistic and practical, you will certainly get the desired results on time. Same is with the Ek Vichaar team, they know their strengths and weaknesses and work according to it</p>
                            <h3>Deepak Shil</h3>
                        </div>
                        <div>
                            <p>There is no greater cause than to educate underprivileged children. In a deeper perspective, you are helping to build a generation which can illuminate the lamp of education in the future.</p>
                            <h3>Ruchi Mishra</h3>
                        </div>
                        <div>
                            <p>The best part with this initiative is that if one doesn’t well enough to donate in monetary terms, he/she can give services directly to the adopted school in terms of teaching, coaching, or by other means.</p>
                            <h3>Anuradha Singh</h3>
                        </div>
                       
                    </Carousel>
                    </div>
                </div>
            </div>
            <div className="testimonial_bg_img">
                <img src={require('../../assets/testimunial_bg_img.png')} />
            </div>
        </div>
    )
}

export default Testimonial;