import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SectionHeader from '../reusable/SectionHeader'
import {connect} from 'react-redux'
import {GetProjectLists} from '../../actions/AuthActions'
import PaypalExpressBtn from 'react-paypal-express-checkout';

class Donate extends Component {
    constructor(props){
        super(props);
        this.state={
            fName: '',
            lName: '',
            email: '',
            donateAmount:'',
            fNameErrorMsg:'',
            lNameErrorMsg:'',
            emailErrorMsg:'',
            projectErrorMsg:'',
            donateAmountErrorMsg:'',
            address:'',
            pinCode:'',
            currency:'',
            currencyErrorMsg:'',
            projectList:[],
            isDone:false,
            project:'',
            message:'',
            isAnonymous:false,
            donatorLists:[]
            
        }
        
    }

    componentWillMount(){
        // const {projectList} = this.state

        let self = this;
        var projectList=[]
        let data ={
            'status':1
        }
        this.props.GetProjectLists(data).then(response=>{
            console.log(response[0],"project listsss","length",response[0].isDeleted)
            for (let i = 0; i < response.length; i++) {
                {response[i].isDeleted == false &&
                projectList.push({
                    name:response[i].name,
                    id :response[i]._id
                });}
            }

    self.setState({projectList})


        }).catch(e=>{
            console.log(e)
        })

       
    }
    handleChange = e => {
        console.log("pppppppppp",e.target.name,"---",e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        let validate = true;
        let {fName, lName, email,donateAmount,project,currency} = this.state
        let validateFirstName = nameRegx.test(String(this.state.fName).toLowerCase());
        let validateLastName = nameRegx.test(String(this.state.lName).toLowerCase());
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        // First Name Validate

        if ((fName === '' || fName === undefined)) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter first name'})
        } else if (!validateFirstName) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter a valid first name'})
        } else {
            this.setState({fNameErrorMsg: ''})
        }


        // Last Name Validate

        if (lName === '' || lName === undefined) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter last name'})
        } else if (!validateLastName) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter valid last name'})
        } else {
            this.setState({lNameErrorMsg: ''})
        }

        // Email Validate

        if (email === '' || email === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter email address'})
        } else if (!emailRegex.test(email)) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }


        if (project === '' || project === undefined) {
            validate = false;
            this.setState({projectErrorMsg: 'Please select project'})
        
        } else {
            this.setState({projectErrorMsg: ''})
        }


        if (currency === '' || currency === undefined) {
            validate = false;
            this.setState({currencyErrorMsg: 'Select currency '})
        
        } else {
            this.setState({currencyErrorMsg: ''})
        }

       //donate amount validate

       if (donateAmount === '' || donateAmount === undefined) {
        validate = false;
        this.setState({donateAmountErrorMsg: 'Please enter donate amount.'})
    }  else {
        this.setState({donateAmountErrorMsg: ''})
    }
       return validate
    }


    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state,"yoooooo")
        if (this.handleValidetion()) {

            //this.setState({isDone:true})

           this.props.history.push({pathname:'/addCard',state:this.state})
           

        } else {

        }
    }
   
   

    toggleChange = () => {
        this.setState({
            isAnonymous: !this.state.isAnonymous,
        });
      }

    


    render(){
        document.body.classList.add('cm_home_page');
        let {fName,lName,email,address,pinCode,donateAmount,fNameErrorMsg,lNameErrorMsg,currencyErrorMsg,
            emailErrorMsg,donateAmountErrorMsg,projectList,isDone,currency,project,message,isAnonymous,projectErrorMsg} = this.state;
            let env = 'sandbox'; 
       // let currency = 'USD'; 
        let total = 333; 
         const client = {
            sandbox :    'AeL9--1hsd7Da9I3tFVIySbce1TJvHqCe5fcldG8Lv5yTJAPm0Kltt1OjDvzTr_PG14w1gA8WnYhq_Xg',
            production: 'EO0CaTwsrXxjRLySSbAQqWFsM_5YfmibXj2q1PPMWi5rCFfxSOw-eafTRlZKth9pmprAFL6Qp--oJvvh',
        }
        console.log("89898989",this.state.donatorLists)
        return(
            <div className="other_page">
                {/* <SectionHeader heading="Contribute Now!" pageName="Contribute Now"   listBtn={'Contributers'} 
                 actionTitle={'Contributers List'}/> */}
                 <br/>

                

                <form onSubmit={this.handleSubmit} className="mb-5">
                    <div className="container">

                        <div className="row mb-5 mt-5">
                            <div className="col-md-4">
                                <h3>Donation Details</h3>
                            </div>
                            <div className="col-md-8">
                                <Link to="/donate-product" className="btn btn-secondary">Contribute in Other Ways !</Link>&nbsp;&nbsp;
                              <Link to="/donators" className="btn btn-secondary ">Contributors List</Link>
                            </div>
                     </div>


                        <div className="row mb-4">
                            <div className="col-md-4">
                                <p className="mb-0 mt-3">Donation Amount*</p>
                            </div>
                           
                             <div className="col-md-2">
                                <select
                                    className="form-control"
                                    name="currency"
                                    //value={currency}
                                    onChange={this.handleChange}  >
                                     <option>Select</option>
                                    <option value="INR">INR </option>
                                    <option value="USD">USD </option>
                                </select>
                                {currencyErrorMsg ?
                          <div class="input_error_msg">{currencyErrorMsg}</div> : null}
                            </div>
                            
                            <div className="col-md-4">
                                   <input
                                    type="number"
                                    className="form-control"
                                    id="donateAmount"
                                    name="donateAmount"
                                    placeholder="Enter amount"
                                    value={donateAmount}
                                    maxLength={6}
                                    onChange={this.handleChange} 

                                />
                                 {donateAmountErrorMsg ?
                                    <div class="input_error_msg">{donateAmountErrorMsg}</div> : null}
                            </div>
                        </div>

                     

                            <div className="row mb-4">
                            <div className="col-md-4">
                            <p className="mb-0 mt-3">Select Project*</p>
                            </div>
                            <div className="col-md-8">
                              
                             <select className="form-control"  name="project" 
                                   onChange={this.handleChange} >
                                        <option>Select</option>
                                  {/* <option>Select</option> */}
                                  {projectList.map(function (item, index) {
                                    return (
                                         <option value={item.id}>{item.name}</option>
                                     )})
                            }
                                </select>

                                {projectErrorMsg ?
                        <div class="input_error_msg">{projectErrorMsg}</div> : null}
                   
                               
                            </div>
                           
                        </div>

                       

                        <div className="row mb-4">
                            <div className="col-md-4">
                            <p className="mb-0 mt-3">Message</p>
                            </div>
                            <div className="col-md-8">
                                <textarea
                                    className="form-control"
                                    placeholder="Enter your message here"
                                    name="message"
                                    id="message"
                                    value ={message}
                                    rows="8"
                                    maxLength={250}
                                    onChange={this.handleChange} 
                                    ></textarea>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4">
                            <h6>Anonymous Donation?</h6>
                            {/* <p className="mb-0 mt-1">Anonymous Donation?</p> */}
                            </div>
                            <div className="col-md-8">
                                <span className="cm_checkbox_wrapper">
                                    CHECK THIS BOX TO HIDE YOUR PERSONAL INFO IN OUR DONATORS LIST
                                    <input
                                        type="checkbox"
                                        className="cm_checkbox_input"
                                        id="anonymous"
                                        name="anonymous"
                                        value="anonymous"
                                        onChange={this.handleChange} 
                                        checked={this.state.isAnonymous}
                                        onChange={this.toggleChange}
                                    />
                                    <span className="cm_checkbox_box"></span>    
                                </span>
                            </div>
                        </div>

                        <div className="row mb-5 mt-5">
                            <div className="col">
                                <h3>Donator Details</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-4">
                                <label htmlFor="profession">First Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fName"
                                    name="fName"  
                                    value={fName}
                                    maxLength={25}
                                    placeholder="Enter first name"
                                    onChange={this.handleChange} 
                                />
                                {fNameErrorMsg ?
                                    <div class="input_error_msg">{fNameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="profession">Last Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lName"
                                    name="lName"  
                                    value={lName}
                                    maxLength={25}
                                    placeholder="Enter last name"
                                    onChange={this.handleChange} 
                                />
                                 {lNameErrorMsg ?
                                    <div class="input_error_msg">{lNameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="profession">Email*</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"  
                                    placeholder="email@domain.com"
                                    value={email}
                                    maxLength={50}
                                    onChange={this.handleChange} 
                                />
                                 {emailErrorMsg ?
                                    <div class="input_error_msg">{emailErrorMsg}</div> : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-8">
                                <label htmlFor="profession">Full Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    placeholder="Enter address"
                                    name="address" 
                                    value={address}
                                    maxLength={50}
                                    onChange={this.handleChange} 
                                />
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="profession">Postal / Pin Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pinCode"
                                    name="pinCode"  
                                    placeholder="Enter pincode"
                                    value={pinCode}
                                    maxLength={6}
                                    onChange={this.handleChange} 
                                />
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Next</button>&nbsp;&nbsp;
                        {isDone && 
                        <button type="submit" className="btn btn-warning">
                            {console.log("paypal",currency,donateAmount)}
                        <PaypalExpressBtn env={env} onSuccess={this.onSuccess} client={client} currency={currency}  onError={this.onError}
                          total={donateAmount} onCancel={this.onCancel}  onAuthorize={this.onAuthorize}  />
                        </button>}
                               

                    </div>
                </form>

                {console.log(currency,"hhhhhhh")}
               
 
       
        
 
        
                {/* {isDone && 
                <PaypalExpressBtn env={env} onSuccess={this.onSuccess} client={client} currency={currency}  onError={this.onError}
                    total={total} onCancel={this.onCancel}  />} */}

            </div>
        )
    }
     onError = (err) => {
        console.log("Error!", err);
    }
     onSuccess = (payment) => {
        console.log("The payment was succeeded!", payment);
    }

     onCancel = (data) => {
        console.log('The payment was cancelled!', data);
     }
     onAuthorize = (data, actions) =>
     actions.payment.execute()
       .then(() => {
         const payment = {
           paid: true,
           cancelled: false,
           payerID: data.payerID,
           paymentID: data.paymentID,
           paymentToken: data.paymentToken,
           returnUrl: data.returnUrl,
         };

         this.onSuccess(payment);
       });

}

const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{GetProjectLists})(Donate);

