import React from 'react'
import SectionHeader from '../reusable/SectionHeader'
import DonatedSection from '../reusable/DonatedSection'
import Testimonial from '../reusable/Testimonial'
import ContactUs from '../reusable/ContactUs'
import {connect} from "react-redux";
import {UserInfoAction,ConnectUs} from '../../actions/AuthActions'
import Success from '../reusable/Success';
import { Loader } from '../SvgIcon';


class Projects extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            token:'',
            isAdmin:false,
            name: '',
            email: '',
            infoText: '',
            phone:'',
            messageText:'',
            nameErrorMsg:'',
            emailErrorMsg:'',
            isSuccess:false,
            loaderOpen:false
        }

    }
    async componentWillMount(){
        console.log('component')
        let token = await localStorage.getItem('accessToken');
        this.setState({token:token})
        console.log("tokennn",typeof token,"---",token)
        let data={
           'userId':" "
        }
        if(token){
            this.props.UserInfoAction(data,token).then(response => {
                console.log(response,"user deatils response 444")
                console.log(response.responseData,"eeee")
                if (response.statusCode == 1) {
                    if (response.responseData.userRole == 0){
                        this.setState({
                            isAdmin:true
                        })
                    }else{
                        this.setState({
                            isAdmin:false
                        })
                    }


             }else {

        }}).catch(e => {
            console.log(e)
         })

     }}

    btnAction=()=>{
        console.log("btn action")
        this.props.history.push('/addproject');
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = e => {
        const {propsValue} = this.props;
        e.preventDefault();
        console.log(this.state)
        const {name,email,phone,infoText,messageText} = this.state;
        if (this.handleValidetion()) {
            this.setState({
               loaderOpen:true
            })
            let data ={
                'name':name,
                'email':email,
                'contactNumber':phone,
                'infoMessage':infoText,
                'message':messageText
            }

            console.log("124444",data)

             this.props.ConnectUs(data).then(response=>{
                console.log("data constacts us",response)
                if(response == 'Success'){
                    this.setState({
                        isSuccess:true,loaderOpen:false
                    })
                 }else{

                 }

             }).catch(e=>{
                 console.log(e)
             })
        }else {

        }
    }

    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        let validate = true;
        let {name, email} = this.state
        let validateFirstName = nameRegx.test(String(this.state.name).toLowerCase());
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        //  Name Validate

        if ((name === '' || name === undefined)) {
            validate = false;
            this.setState({nameErrorMsg: 'Please enter name'})
        } else if (!validateFirstName) {
            validate = false;
            this.setState({nameErrorMsg: 'Please enter a valid name'})
        } else {
            this.setState({nameErrorMsg: ''})
        }

        // Email Validate

        if (email === '' || email === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter email address'})
        } else if (!emailRegex.test(email)) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }

        return validate
    }

    render(){
        document.body.classList.add('cm_home_page');
       let { email,name,emailErrorMsg,nameErrorMsg,isSuccess,phone,infoText,messageText,loaderOpen } = this.state;
    return(
        <div className="other_page">
             { loaderOpen ? <Loader/> :null}
             {!isSuccess &&

             <div>

                  {/* <SectionHeader
            heading={'Our Projects'}
            pageName={'Our Projects'}
            btnTitle={'Add Project'}
            btnAction={this.btnAction}
            isAdmin={this.state.isAdmin} /> */}
            <br/>
            {this.state.isAdmin &&
                     <div>
                     <span class="btn btn-primary float-right" style={{marginRight:'50px'}} onClick={()=>this.props.history.push('/addproject')} >Add Project</span>
                     </div>

                     }

          <div className="section light-bg">
                 <DonatedSection history={this.props.history} />
                <Testimonial />
            </div></div>}

            {!isSuccess &&
                  <div className="section contact_us_form_wrapper">

                    <form onSubmit={this.handleSubmit}>
                    <div className="container">
                        <h3>Let’s Talk for Cooperation!</h3>
                        <div className="row">
                        <div className="form-group col-md-6">
                                <label htmlFor="name">Name*</label>
                                <input type="text"
                                       className="form-control"
                                       id="name"
                                       name="name"
                                       value={name}
                                       maxLength={50}
                                       placeholder="Enter name"
                                       onChange={this.handleChange} />
                                {nameErrorMsg ?<div class="input_error_msg">{nameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="email">Email*</label>
                                <input type="email"
                                       className="form-control"
                                       id="email"
                                       name="email"
                                       value={email}
                                       maxLength={50}
                                       placeholder="email@domain.com"
                                       onChange={this.handleChange} />
                                {emailErrorMsg ? <div class="input_error_msg">{emailErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="number">Contact Number</label>
                                <input type="number" className="form-control" id="number" name="phone"
                                placeholder="Enter contact number"
                                 value={phone} onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">How did you hear about us?</label>
                                <input type="text" className="form-control" id="infoText" name="infoText"
                                placeholder="How did you hear about us?" maxLength={25}
                                value={infoText} onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-12">
                                <label htmlFor="message">Message</label>
                                <textarea class="form-control" id="message" 
                                maxLength={250} name="messageText" value={messageText}
                                placeholder="Enter your message here"
                                rows="3" onChange={this.handleChange}></textarea>
                            </div>

                            <div className="form-group align-self-center col-md-7">
                                <div className="row">
                                    {/* <div className="col-8 align-self-center">Captcha, What is 3 + 5 =</div>
                                    <div className="col-4 align-self-center"><input type="text" className="form-control" id="captcha" name="captcha" placeholder="Answer" /></div> */}
                                </div>
                            </div>
                            <div className="form-group align-self-center col-md-5 text-right">
                                <button className="btn btn-primary">Send</button>
                            </div>

                        </div>
                    </div>
                </form></div>
                }
                {isSuccess &&   <Success btnTitle={'Go To Home'}
                        title={'Kudos to your thought, you are one step closer for being a part of this beautiful journey.Our team member will contact you soon!'}
                         btnAction={()=>this.props.history.push('/')}/>

                }
        </div>
    )}
}

const mapStateToProps = state => {
    console.log("home state",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{UserInfoAction,ConnectUs})(Projects);

