import React from 'react'
import Gallery from 'react-grid-gallery'
import SectionHeader from '../reusable/SectionHeader'
import ImageGallery from 'react-image-gallery';
import Popup from "reactjs-popup";
import {connect} from "react-redux";
import {Gallery_Images} from '../../actions/AuthActions';
import _ from 'lodash'
import { Loader } from '../SvgIcon';

//const imgLists = []

const IMAGES = [
    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Image title will show here"
    },
    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Image title will show here"
    },
    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        caption: "Image title will show here"
    },
   
]

class GalleryImages extends React.Component {

    constructor(props){
        super(props)
        this.state={
            CreateImageashowPopup:false,
            imgLists:[],
            projectImgs:[],
            loaderOpen:true,
            bigImg:''
          
        }
    }
    openCreateImageashowPopup() {
        this.setState({
            CreateImageashowPopup: !this.state.CreateImageashowPopup
        })
    }
    imagePoster(props){
       this.setState({bigImg:props})
    }
    galleryCard=(props)=>{
        console.log("length images",props.length , props)
        if(props != null){
                if(!_.isEmpty(props) && props != ""){ 
                return(
                    <div className="col-lg-3 col-md-6  blockimg" onMouseOver={()=>this.imagePoster(props)}>
                       <img className="response_img" src={props} 
                       alt="EkVichaar" onClick={()=>this.imagePoster(props)}/>
                    </div>
                 )}
        
     }
      
    }
    componentWillMount(){
        this.props.Gallery_Images("bbbb").then(res=>{
            console.log("images gallery res",res.data.responseData)
           this.setState({projectImgs:res.data.responseData,bigImg:res.data.responseData[0],loaderOpen:false},function(
 
            ){
               console.log("state value of gallary",this.state.projectImgs) 
            })

           
        })
          
           
    }

    render(){
        document.body.classList.add('cm_home_page');
          const {loaderOpen,projectImgs,bigImg} = this.state;
          return(
        <div className="other_page">
            {
                loaderOpen ? <Loader/> :null
            }
            <div className="container">
                <div className="gallery_banner">
                    <img src={bigImg} />
                </div>
                 <div className="row">
                   {projectImgs.map(imageUrl => this.galleryCard(imageUrl))}
                 </div>
              </div>
              {/* <Popup    open={this.state.CreateImageashowPopup}
                            closeOnDocumentClick={true} closeOnEscape={true}
                            onClose={this.closeModal} className="image_modal">
              <div className="cm_modal">
                                <div className="modal-header" style={{borderBottom: 'none'}}>

                                    <button type="button" className="close float-left"
                                            onClick={this.closeModal}>&times;</button>
                                </div>
                             
                                <div className="modal-body loginPopup">
                                {projectImgs.map(imageUrl => this.galleryCard(imageUrl))}
                                </div>
                            </div>
                 
              </Popup> */}
        </div>
    )}
}

const mapStateToProps = state => {
    console.log("home state",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{Gallery_Images})(GalleryImages);

//export default ImageGallery23;