import React from 'react'
import { Link } from 'react-router-dom'
import {connect} from "react-redux";

const SectionHeader = (props) => {
    return(
        <div className="section_header">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 text-uppercase">
                        <Link to="/">Home</Link> / {props.pageName}
                    </div>
                    <div className="col-md-5 text-center">
                        <h2>{props.heading}</h2>
                    </div>
                  
                   

                    {props.isAdmin &&
                    <div className="col-md-4 text-right">
                     {/* <Link class="btn btn-secondary" to="/donate">{actionTitle}</Link> */}
                     {props.isUpdate &&
                     <span class="btn btn-secondary" onClick={()=>props.updateBtnAction()}>{props.updateBtn}</span>}&nbsp;&nbsp;
                     <span class="btn btn-warning" onClick={()=>props.btnAction()}>{props.btnTitle}</span>
                    </div>
                   }
                   
                    
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    console.log("123456",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps)(SectionHeader);

