import React from 'react'
import { Link } from 'react-router-dom'


const Person = (props) => {
    console.log("ppp",props)
    return(
        <div className="col-md-4 person-card" >
            <img className="img-fluid" src={props.path} className="rounded-circle"/>
            <br/><br/>
            <p>{props.title}</p>
            {/* <p><small>Founder</small></p> */}
        </div>
        //border-radius: 50%;
    )
}

class OurTeam extends React.Component {
    constructor(props){
        super(props)
        this.state={
            imgPath:"",
            title:''
        }
    }
  
    render(){
    return(
        <div className="container">
           
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2>Key Persons</h2>
                    <p>Ek Vichaar has some key members who act as watchdogs to ensure that are efforts must hold the values of our vision</p>
                </div>
            </div>
            

            <div className="row our_team_section">
                <div className="col-lg-7 align-self-center">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <img className="rounded-circle cm_width" 
                            src={require('../../assets/members/ashu.jpg')} />
                        </div>
                        <div className="col-md-6 align-self-center">
                            <h3>Ashutosh Singh</h3>
                     
                    
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 align-self-center">
                    <p>His social activism and a vision to transform the lives of children by imparting education is our strength. He made us believe that it is all about continuous efforts and selfless attitude to achieve any goal.</p>
                </div>
            </div>

            <div className="row">
                <Person title="Rahul Singh" path={require('../../assets/members/rahul.jpg')} />
                <Person title="Girijesh Kumar" path={require('../../assets/members/grijesh.jpg')}/>
                <Person title="Abhinav Bajpayee" path={require('../../assets/members/abhinav.JPEG')}/>
                <Person title="Shalu Chaudhary" path={require('../../assets/members/shalu.jpg')}/>
                <Person title="Rohit Pathak" path={require('../../assets/members/rohit.jpg')}/>
                <Person title="Rahul Narang"  path={require('../../assets/members/rahulnarang.jpg')}/>

                <div className="col btn_wrapper text-center mb-5 mt-5">
                    {/* <Link to="/registration" className="btn btn-primary">Join Us</Link> */}
                    {/* <button type="submit" className="btn btn-primary"  
                         >&nbsp;&nbsp;&nbsp;Join Us &nbsp;&nbsp;&nbsp;</button> */}
                </div>
                     
                       
                    

                   
            </div>
        </div>
    )}
}

export default OurTeam;