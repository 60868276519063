import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {connect} from "react-redux";
import {UserInfoAction} from '../../actions/AuthActions';
import _ from 'lodash'
import { Loader } from '../SvgIcon';

class MyProfile extends Component {
    constructor(props){
        super(props);
        this.state ={
            fullName:'',
            email:'',
            contactNumber:'',
            gender:'',
            profession:'',
            dob:'',
            address:'',
            userImage: require('../../assets/user_placeholder.png'),
            loaderOpen:true
        }
    }
    async componentWillMount(){
        console.log('component')
        let token = await localStorage.getItem('accessToken');
        console.log("tokennn",typeof token,"---",token)
        let data={
           'userId':" "
        }
        if(token){
            this.props.UserInfoAction(data,token).then(response => {
                console.log(response,"user deatils response 444")
                let date = new Date(response.responseData.dob);
                let day = date.getDate()
                let month = date.getMonth()+1
                if (day < 10) {
                    day = '0' + day;
                  } 
                  if (month < 10) {
                    month = '0' + month;
                  }
                let dob = day+'/'+month+'/'+date.getFullYear()
                console.log("dateeee",date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear());
                if (response.statusCode == 1) {
                    console.log(_.isEmpty(response.responseData.userProfiles) ,"iiiiii")
                    let fName = response.responseData.firstName.slice(0,1).toUpperCase()+response.responseData.firstName.slice(1, response.responseData.firstName.length)
                    let lName = response.responseData.lastName.slice(0,1).toUpperCase()+response.responseData.lastName.slice(1, response.responseData.lastName.length)
                    this.setState({
                    fullName:fName+" "+lName,
                    contactNumber:response.responseData.contactNumber,
                    email:response.responseData.email,
                    profession:response.responseData.profession,
                    dob:dob,
                    address:response.responseData.address,
                    userImage:_.isEmpty(response.responseData.userProfiles)?require('../../assets/user_placeholder.png'):response.responseData.userProfiles,
                    loaderOpen:false
                    })
               if(response.responseData.gender == 1){
                this.setState({
                    gender:'Male'
                })
            }else if (response.responseData.gender == 2){
                this.setState({
                    gender:'Female'
                })
            }else{
                this.setState({
                    gender:'Other'
                })
            }
                    
       }else {
                     
        }}).catch(e => {
            console.log(e)
         })
            
     }}
   

    render(){
        console.log('render')
        document.body.classList.add('cm_home_page');
        const {fullName,email,contactNumber,profession,dob,gender,address,loaderOpen} = this.state;
        let { userImage } = this.state;
        return(
            <div className="other_page fix-footer">
                 {
                    loaderOpen ? <Loader/> :null
                }
                <div className="mt-5 mb-5 my_rofile_page">
                    <div className="container">
                        <div className="row">

                            <div className="col-sm-12 mt-5 mb-5 p-5 cm_file_upload_wrapper">
                                <span
                                    style={{backgroundImage: `url(${userImage})`}}
                                    className="file_upload_img"
                                ></span>
                                <div className="cm_btn_group">
                                    <Link to="/edit-profile" className="btn btn-primary">Edit Profile!</Link>
                                    <Link to="/change-password" className="btn btn-primary">Change Password</Link>
                                </div>
                            </div>
                        
                            <div className="form-group col-lg-8 col-md-6">
                                <label htmlFor="name">Name</label>
                                <p>{fullName}</p>
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Email</label>
                                <p>{email}</p>
                            </div>

                            <div className="form-group col-lg-8 col-md-6">
                                <label htmlFor="name">Full Address</label>
                                <p>{address}</p>
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Contact Number</label>
                                <p>{contactNumber}</p>
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Profession</label>
                                <p>{profession}</p>
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="gender">Gender</label>
                                <p>{gender}</p>
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession">Date of Birth</label>
                                <p>{dob}</p>
                            </div>
                            
                         </div>
                    </div>
                </div>
               
            </div>
        )
    }

}

const mapStateToProps = state => {
    console.log("123456",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{UserInfoAction})(MyProfile);

