import React from 'react'
import './cercle.css'
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'
import {GetProjectLists} from '../../actions/AuthActions'
//import Progress from 'react-progressbar';
import { Progress } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import _ from 'lodash';
let pp;

const renderCard=(projectList,history)=>{
    let result=[];
    console.log("hgjyuftgchvnhjbgkfyutch",projectList,"history",history,"---",projectList.length);
        if (projectList.length > 0){
            console.log("jjjj")
        projectList.map(function (item, index) {
            console.log("qwertyuio#####",index,"qazxdew",item,item.raised,item.target,item.images)
            var percentage = (item.raised/item.target)*100;
            var imgPath = item.images != "" ?item.images: require('../../assets/slider_1.jpg')
            pp = item.name;
            //let img = item.images !=null ? item.images :
            let data ={
                'title':item.name,
                'target':item.target,
                'raise':item.raised,
                'projectId':item._id,
                'projectImg':item.images[0],
                'projectDescription':item.description,
                'createProject':item.created,
                'member':item.members
            }

            {item.isDeleted === false &&
            result.push(
            <div className="col-lg-3 col-md-6 cm_card" >
            <div className="cm_card_img">
                
                <img className="img-fluid cm_min_h"
                  src={imgPath}
                  />
                 <span className="serach_icon"
                onClick={()=>history.push({pathname:'/project-detail',state:data})}><img src={require('../../assets/search-icon.png')} /></span>

         </div>
            <div className="cm_card_content">
                <h4>{item.name}</h4>
                <h5>Target :- {item.target} </h5>
                 <div className="donated">
                    <h5>DONATED <span>{percentage.toFixed(0)} %</span></h5>
                    <Progress value={percentage.toFixed(0)} />
                    {/* <span className="donated_line"></span> */}
                </div>

                <h5>Rs. {item.target - item.raised} To Go</h5>
            </div>
        </div> )}

        })}
    return result;
}

const renderInitialProjectCard=(projectList)=>{
    console.log("12345",projectList)
    let name = ""
    let desc = ""
    let target =  0
    let raised =  0
    var percentage = 0;
    let images =""

    for (let i= 0 ;i <=projectList.length;i++){
        if(projectList[i] != null ){
            if(projectList[i].isDeleted == false){
                console.log("jjjjjj",projectList[i])
                 name = projectList[i] != null ? projectList[i].name : "hhh"
                 desc = projectList[i] != null ? projectList[i].description : "hhh"
                 target = projectList[i] != null ? projectList[i].target : 0
                 raised = projectList[i] != null ? projectList[i].raised : 0
                 images = projectList[i] != " " ? projectList[i].images :require('../../assets/our-vision.jpg')
                 percentage = (raised/target)*100;
                break;
            }

        }
    }

    return (
        <div className="container p-0">
        <div className="row no-gutters">
        <div className="col-md-3 total_donated_bg" style={{backgroundImage: `url(${images})`}}></div>
        <div className="col-md-9 donated_main_content">
            <div className="row justify-content-centerjustify-content-center no-gutters">
                <div className="col-md-8 align-self-center">
                    <h3>{name}</h3>
                    <p>{desc}</p>
                    <Link to="/donate" className="btn btn-primary" >Contributed Now!</Link>
                </div>
                <div className="col-md-4 align-self-center text-right">
                <CircularProgressbar value={percentage} maxValue={100} 
                text={`${percentage.toFixed(0)}%`} 
                strokeWidth={15}
                background={true}
                strokeWidth={5}
                styles={{
                    path: {
                      stroke: `orange`,
                      strokeLinecap: 'butt',
                      transition: 'stroke-dashoffset 0.5s ease 0s',
                      transform: 'rotate(0.25turn)',
                      transformOrigin: 'center center',
                    },
                    trail: {
                      stroke: '#87CEEB',
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',
                      transform: 'rotate(0.25turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: '#000',
                      // Text size
                      fontSize: '16px',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                      fill: '#fff',
                    },
                  }}
                
                />
               
                </div>
            </div>
        </div>
    </div></div>)
   

    
}

let list =[]


class DonatedSection extends React.Component{

    constructor(props){
        super(props);
        console.log("abcdd",props)
        this.state={
           projectList:[],
           projectName:[]
        }
    }

    async UNSAFE_componentWillMount(){
        const {projectList,projectName} = this.state
        let list= [];
        let self = this;
        let data ={
            'status':1
        }
        this.props.GetProjectLists(data).then(res=>{
            console.log("res",res)
                 this.setState({projectList:res})
                 console.log(projectList,"88888888","type",typeof projectList)
                 res.map(function (item, index) {
                    //console.log(item.name,"pppppp")
                    list.push(item.name)
                    self.setState({projectName:item.name})
                    //console.log(list,"QQQQQ")
                })
                })
               

  }


    render(){
        const{history}=this.props;
        console.log("a1111",history)
        const {projectList,projectName} = this.state;
        let self = this;
        let listData=[];
        listData.push(projectList)
        let aa = projectList != null ?  projectList[0] :"hhh"
        console.log("000000000",listData[0])
       

    return(
        <div className="container p-0">
            <div className="row no-gutters">
                <div className="col-sm-12 text-center">
                    <h2>How are we going to achieve it?</h2>
                    <p>Ek Vichaar is a network of professionals who knows how to eliminate the bottlenecks which hinder the prosperity of primary education in India and to provide what 
                        is necessary in it. It will be an open social platform where all the corporates & individuals are free to donate capital to the cause. It covers every aspect which needed to provide a quality education ecosystem in primary education</p>
                    <img className="img-fluid  mt-5 section_img" src={require('../../assets/right-to-education-banner.png')} />
                </div>
            </div>

            <div className="row no-gutters">
                <div className="col-md-3 total_donated_bg" style={{backgroundImage: `url(${require('../../assets/right-to-education.jpg')})`}}/>
               {renderInitialProjectCard(projectList)}
            </div>
            <div className="row no-gutters">
                 {console.log("project list 2222",projectList,)}
                  {renderCard(projectList,history)}


            </div>


           <div className="btn_wrapper text-center mb-5 mt-5">
                <a href="/projects" className="btn btn-secondary">View More</a>
            </div>

        </div>
    )
    }}


const mapStateToProps = state => {
     console.log("123456",state)
     return {};
};

export default connect(mapStateToProps,{GetProjectLists})(DonatedSection);

