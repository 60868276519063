import React, { Component } from 'react'
import {Switch} from  'react-router-dom'
import Header from './components/template/header/Header'
import Footer from './components/template/Footer'
import Routers from './Routers'
import { Provider } from 'react-redux';
import {store} from "./helpers/Store";
import {createBrowserHistory} from 'history';
export const history = createBrowserHistory();

history.listen((location, action) => {
  window.scrollTo(0, 0)
})







class App extends Component {

handleScroll = () => {
  window.pageYOffset > 100
  ? document.querySelector('body').classList.add('scroll-header')
  : document.querySelector('body').classList.remove('scroll-header');
}

  render() {
    window.addEventListener('scroll', this.handleScroll);
   // console.log(history,"34343")
    return (
      <div className="app" history={history}>
        <Provider store={store}>
            <Header />
            <Routers />
            <Footer />
            {/* <a href="/donate" id="myBtn" title="Go to top">Donate</a> */}

        </Provider>

        
      </div>
    );
  }
}

export default App;
