import React from 'react'
import { Route,Redirect,Switch} from 'react-router-dom'
import axios from 'axios';

import Home             from './components/home/Home'
import Contact          from './components/contact/Contact'
import Projects         from './components/projects/Projects'
import ProjectDetail    from './components/projects/ProjectDetail'
import ImageGallery     from './components/imageGallery/ImageGallery'
import TheTeam          from './components/theTeam/TheTeam'
import Registration     from './components/registration/Registration'
import Login            from './components/login/Login'
import Donate           from './components/contribute/Donate'
import DonateProduct    from './components/contribute/DonateProduct'
import MyProfile        from './components/profile/MyProfile'
import EditProfile      from './components/profile/EditProfile'
import ChangePassword   from './components/profile/ChangePassword'
import Members          from './components/members/Members'
import Donators         from './components/donators/Donators'
import ForgotPassword   from "./components/forgotPassword/ForgotPassword";
import DonatedSection   from './components/reusable/DonatedSection';
import Success          from './components/reusable/Success';
import JoinUs           from './components/join-us/JoinUs';
import AddProject       from './components/projects/AddProject';
import ContactUs from './components/reusable/ContactUs';
import AddCard from './components/payment/AddCard';
import Payment from './components/payment/Payment';
import PaymentDetails from './components/payment/PaymentDetails';
import UpdateProject from './components/projects/UpdateProject';
import PaymentScreen from './components/contribute/PaymentScreen';
import ResetPassword from './components/resetPassword/ResetPassword';
import Page404 from "./ErrorPage/Page404";

function isLoggedIn() {
  const token = localStorage.getItem('accessToken');
   if (!token) {
     console.log("isLoggin")
     return false
    }
   else {
     return true;
    }
}
function PrivateRoute({ component: Component, ...rest }) {
  return (
      <Route
          {...rest}
          render={props =>
              (
                  isLoggedIn() ? (<Component {...props} />) : (
                      <Redirect
                          to={{
                              pathname: "/",
                          }}
                      />
                  ))

          }
      />
  );
}

function OuterRoute({ component: Component, ...rest }) {
  return (
      <Route
          {...rest}
          render={props =>
              (
                  isLoggedIn() ? (<Redirect
                      to={{
                          pathname: "/",
                      }}
                  />) : (

                      <Component {...props} />
                  ))

          }
      />
  );
}




class Routers extends React.Component{

  render(){

    let accessToken = localStorage.getItem('accessToken')
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    axios.defaults.headers.common['Authorization'] = `${'Basic ZWtfdmljaGFyX2FkbWluOmFkbWluQGVrVmljaGFy'}`;

   return(
      <React.Fragment>
        <div>
            <Switch>
        <Route exact path="/"            component={ Home } />
        <Route path="/projects"          component={ Projects } />
        <Route path="/project-detail"    component={ ProjectDetail } />
        <Route path="/gallery"           component={ ImageGallery } />
        <Route path="/team"              component={ TheTeam } />
        <Route path="/contact-us"        component={ Contact } />
        <Route path="/contact"        component={ ContactUs } />
        <Route path="/registration"      component={ Registration } />
        <Route path="/login"             component={ Login } />
        <OuterRoute path="/forgot-password"   component={ForgotPassword}/>
        <Route path="/donate"            component={ Donate } />
        <Route path="/donate-product"    component={ DonateProduct } />
        <PrivateRoute path="/profile"           component={ MyProfile } />
        <PrivateRoute path="/edit-profile"      component={ EditProfile } />
        <PrivateRoute path="/change-password"   component={ ChangePassword } />
        <Route path="/members"           component={ Members } />
        <Route path="/donators"          component={ Donators } />
        <Route path ="/donate-section"   component={ DonatedSection}/>
        <Route path ="/success"          component={ Success}/>
        <Route path ="/join-us"          component={ JoinUs}/>
        <Route path="/addproject"        component={AddProject} />
        <PrivateRoute path="/updateproject"  component={UpdateProject}/>
        <Route path="/addCard"      component={AddCard}/>
        <Route path ="/paymentDetails" component={PaymentDetails}/>
        <Route path="/payment-screen" component={PaymentScreen}/>
        <Route path="/reset-password/:id"   component={ResetPassword}/>
        <Route name="Page 404" component={Page404} />
        </Switch>
        </div>
      </React.Fragment>
   )
  }


}

export default Routers;
