import setting from './settings'

export default (() => {
 
  return {
     'AUTH': 'Basic ZWtfdmljaGFyX2FkbWluOmFkbWluQGVrVmljaGFy',


     // user & admin module

     'USER_LOGIN': setting.api.url + "user/login",
     'USER_LOGOUT': setting.api.url + "user/logout",
    

    'USER_REGISTER': setting.api.url + "user/register",
    'USER_FORGOT_PASSWORD': setting.api.url + "user/forgotPassword",
    'USER_VERIFY_EMAIL': setting.api.url + "user/verifyEmail",
    'USER_EXIST_EMAIL':setting.api.url + "user/emailExists",
    'USER_RESET_PASSWORD': setting.api.url + "user/reset",
    'USER_CHANGE_PASSWORD': setting.api.url + "user/changePassword",
    'USER_CONATCTUS':setting.api.url + "user/contactus",


    'USER_PROFILE': setting.api.url + "user/getProfile",
    'USER_EDITPROFILE':setting.api.url + "user/editProfile",
    'USER_DELETEPROFILE':setting.api.url + "user/deleteProfile",
    'USER_ADD_CARD':setting.api.url + "user/addCard",
    'USER_CARD_INFO': setting.api.url + "user/getCard",
    'USER_DELETE_CARD': setting.api.url + "user/deleteCard",
    'USER_UPLOAD_PHOTO' :setting.api.url + "user/upload",
   

     // members

     'MEMBER_LISTS':setting.api.url+"user/members",


     // join us 
       
     'JOIN_US':setting.api.url + "joinUS/joinUs",



    
    // project module

    'CREATE_PROJECT': setting.api.url + "project/createProject",
    'DELETE_PROJECT':  setting.api.url + "project/deleteProject",
    'UPDATE_PROJECT': setting.api.url + "project/updateProject",
    'GET_PROJECT'   : setting.api.url + "project/getProject",
    'UPLOAD_PROJECT_IMAGES' :setting.api.url +"project/uploadImages",
    'GALLERY_IMAGES' :setting.api.url + "project/gallery",

    // donate 

    'DONATE_PRODUCTS': setting.api.url + "donator/donator",
    'DONATE_MONEY':setting.api.url + "donatorMoney/payment",
    'TOTAL_DONATION':setting.api.url + "donatorMoney/totaldonator",
    'DONATOR_LIST' : setting.api.url + "donatorMoney/donatorlist"

   
    
 }
})()