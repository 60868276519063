import React, { Component } from 'react'
import { PayPalButton } from "react-paypal-button-v2";
import SectionHeader from '../reusable/SectionHeader';
import Success from '../reusable/Success';


class PaymentScreen extends Component {

       constructor(props){
           super(props);
           console.log("props of payment screen",props)
           this.state={
               currency:"INR",
               total:100,
               paymentSuccess:false,
               donateAmount:props.location.state.donateAmount,
               fName:"kkkkk",
               value :props.location.state


           }

       }
    paymentHandler=(e)=> {
       
      e.preventDefault();
        let self = this
        const { donateAmount,fName } = self.state;
        let options = {
          currency: 'INR',
          key: "rzp_test_C1iyUOkLTeWAiX",
          amount:donateAmount*100, // 2000 paise = INR 20, amount in paisa
          name: 'EkVichaar',
          description: 'Make a contribution to EkVichhar',
          image: require('../../assets/login-bg.png'),

          handler(response){
            alert(response.razorpay_payment_id);
            const paymentId = response.razorpay_payment_id;
            const url = process.env.URL+'/api/v1/rzp_capture/'+paymentId+'/'+donateAmount;
            // Using my server endpoints to capture the payment
            fetch(url, {
              method: 'get',
              headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
              }
            })
            .then(resp =>  resp.json())
            .then(function (data) {
              console.log('Request succeeded with JSON response', data);
              self.setState({
                refund_id: response.razorpay_payment_id
              });
            })
            .catch(function (error) {
                 console.log('Request failed', error);
            });
          },
          "prefill": {
            "name": "EkVichaar",
            "email": "support@ekbichaar.org"
          },
          "notes": {
            "address": "Noida,India"
          },
          "theme": {
            "color": "#9D50BB"
          }
        };
        
        const rzp = new window.Razorpay(options);
        rzp.open();
      }
   

    render(){
        document.body.classList.add('cm_home_page');
         const client = {
            sandbox :    'AeL9--1hsd7Da9I3tFVIySbce1TJvHqCe5fcldG8Lv5yTJAPm0Kltt1OjDvzTr_PG14w1gA8WnYhq_Xg',
            production: 'EO0CaTwsrXxjRLySSbAQqWFsM_5YfmibXj2q1PPMWi5rCFfxSOw-eafTRlZKth9pmprAFL6Qp--oJvvh',
        }
        
        return(
           
           <div className="other_page fix-footer">

              {this.state.paymentSuccess &&
                  <Success btnTitle={'Go To Home'} 
                  title={'Kudos to your thought, you are one step closer for being a part of this beautiful journey.Our team member will contact you soon!'}
                  btnAction={()=>this.props.history.push('/')}/>
                }
                {!this.state.paymentSuccess &&

                  <div className="container text-center">
                    <div className="row justify-content-center pb-2">
                        <div className="col-md-5 order-2 align-self-center">
                            <img className="success_logo_img" src={require('../../assets/successLogo.png')} />
                            <h4 className="gray_text">You are one step closer for being a part of this beautiful journey!</h4>
                        </div>
                        
                  </div>
                  <div class="container pt-2 mb-2">
                 
                  {/* <PayPalButton
                    currency={this.state.currency}
                    currency_code={this.state.currency}
                    amount={this.state.total}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    currency_code: "USD",
                                    value: this.state.total
                                }
                            }]
                        });
                    }}
                    onSuccess={(details, data) => {
                        this.setState({paymentSuccess:true})
                        alert("Transaction completed by " + details.payer.name.given_name);
               
                        // OPTIONAL: Call your server to save the transaction
                        return fetch("/paypal-transaction-complete", {
                          method: "post",
                          body: JSON.stringify({
                            orderID: data.orderID
                          })
                        });
                      }}
                      onCancel={this.onCancel}
                     onApprove={(data, actions) => {
                        // Capture the funds from the transaction
                        return actions.order.capture().then(function(details) {
                            // Show a success message to your buyer
                            alert("Transaction completed by " + details.payer.name.given_name);

                            // OPTIONAL: Call your server to save the transaction
                            return fetch("/paypal-transaction-complete", {
                                method: "post",
                                body: JSON.stringify({
                                    orderID: data.orderID
                                })
                            });
                        });
                    }}
                  /> */}
                       <div className="form-group align-self-center col-md-12 ">
                                <button className="btn btn-primary" onClick={this.paymentHandler}><i class="fa fa-credit-card-alt" aria-hidden="true"></i>&nbsp;&nbsp;Payment using Razorpay</button>&nbsp;&nbsp;
                                <button className="btn btn-primary" 
                                onClick={()=>this.props.history.push({pathname:"/addCard",state:this.state.value})}><i class="fa fa-cc-stripe" aria-hidden="true"></i>&nbsp;&nbsp;Payment using Stripe</button>
                            </div>
                           
                  </div>
                  </div>
                }
              </div>
         )
    }
     onError = (err) => {
        console.log("Error!", err);
    }
     onSuccess = (payment) => {
        console.log("The payment was succeeded!", payment);
    }

     onCancel = (data) => {
        console.log('The payment was cancelled!', data);
     }
     onAuthorize = (data, actions) =>
     actions.payment.execute()
       .then(() => {
         const payment = {
           paid: true,
           cancelled: false,
           payerID: data.payerID,
           paymentID: data.paymentID,
           paymentToken: data.paymentToken,
           returnUrl: data.returnUrl,
         };
           this.onSuccess(payment);
       });

}



export default PaymentScreen;

