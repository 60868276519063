import React from 'react'
import OurTeam from '../reusable/OurTeam'
import SectionHeader from '../reusable/SectionHeader'

const TheTeam = () => {
    document.body.classList.add('cm_home_page');
    return(
        <div className="other_page">
            {/* <SectionHeader heading={'The Team !'} pageName={'The Team'} actionTitle={'Add Member'} /> */}
             <br/><br/>
             <OurTeam />
        </div>
    )
}

export default TheTeam;