import React,{ Component } from 'react'
import SectionHeader from '../reusable/SectionHeader'
import {MemberLists} from '../../actions/AuthActions'
import {connect} from "react-redux";
import { Loader } from '../SvgIcon';

class Members extends Component {
    
    constructor(props){
        super(props);
        this.state={
            memberLists:[],
            loaderOpen:true
        }
    }

    componentWillMount(){
        let data ={
            'userRole':1
        }
        this.props.MemberLists(data).then(res=>{
            console.log(res.data.responseData,"listsss")
            this.setState({memberLists:res.data.responseData,loaderOpen:false})


        }).catch(e=>{
            console.log(e)
        })
    }
   
    memberCard=(props)=>{
        console.log("ppp",props)
        if(props != null){
            return props.map(function(item,index){
                console.log(item.firstName,"popop")
                return(
                 <li><img src={item.userProfiles === '' ?require('../../assets/userProfilePlaceholder.png'):item.userProfiles} />
                         <p>{item.firstName} {item.lastName}</p></li>
                    
                   
                )
         })
     }
      
    }


    render(){
        document.body.classList.add('cm_home_page');
        console.log(this.state.memberLists,"0000000")
        return(
            <div className="other_page">
                 {
                    this.state.loaderOpen ? <Loader/> :null
                }
                {/* <SectionHeader heading="Our Members" pageName="Members" /> */}

                <div className="container pt-5 mb-5 text-center">
                    <h2>Our Members</h2>
                    <p>Ek Vichaar has some key members who act as watchdogs to ensure that are efforts must hold the values of our vision</p>
                </div>
                <br/>
                 <div className="container mb-5">
                    <ul className="member_list">
                    <h2>List of Members registered till now</h2>   
                    <br/> 
                    {this.memberCard(this.state.memberLists)}
                 </ul>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{MemberLists})(Members);

