import React, { Component } from 'react'
import Success from '../reusable/Success';

import { Addcard , Checkicon , Mastercard , Norecord} from  '../SvgIcon';

class PaymentDetails extends Component {

    constructor(props){
        super(props)
        this.state ={
            isSuccess:false
        }

       
    }
    handlePayment=()=>{
        console.log("paymentssss")
        this.setState({
            isSuccess:true
        })

    }

    
    render() {
        document.body.classList.add('cm_home_page');
        return(
           <React.Fragment>
                     <div className="other_page">
               <div className="container">
               <br/> <br/> <br/>
               {!this.state.isSuccess &&
                 
                
               <div className="col-sm-8 offset-sm-2">
                            <div className="dashboard-box">
                            
                            <h4 className="graycolorh4">Payment Methods</h4>
                                <div className="table-responsive">
                                        <table className="table cm_table payment-table">
                                            <tr>
                                                <td className="text-align: center;"><span className="card-icon"><Mastercard/></span></td>
                                                <td>
                                                    <div className="cardtext">
                                                        <p className="card-number">Mastercard **** **** **** 0000</p>
                                                        <p className="expire_date">Expires 00/00</p>
                                                        <div className="float-right">
                                                            <a class="remove_btn">REMOVE</a>&nbsp;&nbsp;
                                                            <a class="btn btn-primary">&nbsp;&nbsp;EDIT&nbsp;&nbsp;</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td> <span className="gray_tick"><Checkicon/></span></td>
                                            </tr>
                                            <tr>
                                                <td className="text-align: center;"><span className="card-icon"><Mastercard/></span></td>
                                                <td>
                                                    <div className="cardtext">
                                                        <p className="card-number">Mastercard **** **** **** 0000</p>
                                                        <p className="expire_date">Expires 00/00</p>
                                                        <div className="float-right">
                                                            <a class="remove_btn">REMOVE</a>&nbsp;&nbsp;
                                                            <a class="btn btn-primary">&nbsp;&nbsp;EDIT&nbsp;&nbsp;</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><span className="gray_tick"><Checkicon/></span></td>
                                            </tr>
                                            <tr onClick={()=>this.props.history.push('/addCard')}>
                                                <td className="text-align: center;">
                                                    <span className="card-icon"><Norecord/></span></td>
                                               
                                                <td> <p className="card-number">Add a payment method</p></td>
                                                
                                            </tr>
                                          
                                        
                                        </table>
                                        <br/>
                                        <div class="text-center"><p onClick={this.handlePayment} class="btn btn-primary">&nbsp;PROCEED&nbsp;</p></div>
                                   </div>
                                </div>
                            </div>}
                            <br/> <br/>
               </div>
               {this.state.isSuccess &&
                  <Success btnTitle={'Go To Home'} 
                  title={'Kudos to your thought, you are one step closer for being a part of this beautiful journey.Our team member will contact you soon!'}
                  btnAction={()=>this.props.history.push('/')}/>
                }
               </div>
           </React.Fragment>
        )
    }
}


export default PaymentDetails;

