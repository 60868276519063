import React from 'react'

const scoialIconStyle ={
    width: '30px',
    height: '30px',
    display: 'block',
    backgroundImage: `url(${require('../../assets/social-icons.png')})`,
}

const Footer = () => {
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 align-self-center order-md-2">
                        <ul>
                            <li><a target ="_blank" href="https://www.facebook.com/pg/ekvichaar.org/" style={scoialIconStyle} className="icon_facbook"></a></li>
                            <li><a target ="_blank" href="https://twitter.com/EVichaar" style={scoialIconStyle} className="icon_twiter"></a></li>
                            <li><a target ="_blank" href="https://www.youtube.com/channel/UC-afhxU76-bQtbQd3xe6oYQ?view_as=subscriber" style={scoialIconStyle} className="icon_youtube"></a></li>
                            <li><a  target ="_blank" href="https://www.instagram.com/Ek_Vichaar/" style={scoialIconStyle} className="icon_insta"></a></li>
                            <li><a  target ="_blank" href="https://aboutme.google.com/u/2/?referer=gplus&pageId=none" style={scoialIconStyle} className="icon_googleplus"></a></li>
                           
                        </ul>
                    </div>
                    <div className="col-md-6 align-self-center order-md-1">
                        <p>Copyright © 2019 & Powered by: EkVichaar</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;