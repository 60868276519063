import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SectionHeader from '../reusable/SectionHeader'
import {connect} from "react-redux";
import {UserInfoAction,DeleteProjects} from '../../actions/AuthActions'
import { Progress } from 'reactstrap';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Loader } from '../SvgIcon';


class ProjectDetail extends Component {
    constructor(props){
        super(props);
        console.log("data project details",props)
         this.state = {
            isAdmin:false,
            projectTitle:props.location.state != undefined ? props.location.state.title :null,
            projectTarget:props.location.state != undefined ?props.location.state.target : null,
            projectRaise:props.location.state  != undefined ? props.location.state.raise:null,
            projectId:props.location.state != undefined ?props.location.state.projectId :null,
            projectStartDate : props.location.state != undefined ? props.location.state.createProject :null,
            projectDescription:props.location.state != undefined ? props.location.state.projectDescription:null,
            projectImage:props.location.state != undefined && props.location.state.projectImg != "" ? props.location.state.projectImg : require('../../assets/slider_1.jpg'),
            //projectImage:require('../../assets/slider_1.jpg'),
            projectMember :props.location.state != undefined ? props.location.state.member:null,
            loaderOpen:false
        }
    }
    async componentWillMount(){
        console.log('component')
        let token = await localStorage.getItem('accessToken');
        this.setState({token:token})
        console.log("tokennn",typeof token,"---",token)
        let data={
           'userId':" "
        }
        if(token){
            this.props.UserInfoAction(data,token).then(response => {
                console.log(response,"user deatils response 444")
                console.log(response.responseData,"eeee")
                if (response.statusCode == 1) {
                    if (response.responseData.userRole == 0){
                        this.setState({
                            isAdmin:true
                        })
                    }else{
                        this.setState({
                            isAdmin:false
                        })
                    }
                   
              
             }else {
                     
        }}).catch(e => {
            console.log(e)
         })
            
     }}
     createNotification = (type) => {
        switch (type) {
            case 'info':
              NotificationManager.info('Info message');
              break;
            case 'success':
              NotificationManager.success('Deleted Successfully!');
              this.setState({loaderOpen:false})
              this.props.history.push('/')
              break;
            case 'warning':
              NotificationManager.warning('Invalid email or password');
              break;
            case 'error':
              NotificationManager.error('User not exits');
              break;
          }
        
      };

    deleteProject=()=>{
        console.log("btn action")
        const {token,projectId} = this.state;
        this.setState({loaderOpen:true})
        let data={
             'isDeleted':true,
             'projectId':projectId
        }
        if(token){
            this.props.DeleteProjects(data,token).then(response=>{
                console.log(response,"delete project")
                if(response === "Success"){
                    this.createNotification('success')  
                }else{

                }

            }).catch(e=>{
                console.log(e)
                this.setState({loaderOpen:false})
            })
        }

        //this.props.DeleteProjects
       // this.props.history.push('/');
    }
    updateProject=()=>{
        console.log("update button action")
        const {projectId,projectTitle,projectTarget,projectRaise,projectDescription,projectMember} = this.state;
        console.log("iddd",projectId)
        let data ={
           'projectId':projectId,
           'title':projectTitle,
           'target':projectTarget,
           'raised':projectRaise,
           'projectDescription':projectDescription,
           'member':projectMember
        }
        this.props.history.push({pathname:'/updateproject',state:data});
    }

    render(){
        document.body.classList.add('cm_home_page');

        let { projectImage,projectTitle,projectTarget,projectRaise,loaderOpen,projectDescription,projectStartDate} = this.state;
        console.log("target",projectTarget,"--",projectRaise)
        var percentage = (projectRaise/projectTarget)*100;
        var date = new Date(projectStartDate)
        console.log("dateee",date.toDateString())
        return(
            <div className="other_page">
                {
                    loaderOpen ? <Loader/> :null
                }
                 <NotificationContainer/>
                {/* <SectionHeader heading={projectTitle} 
                 pageName={projectTitle} actionTitle={'Add Project'} btnTitle={'Delete Project'} 
                 btnAction={this.deleteProject}
                 updateBtn={'Update Project'}
                 isUpdate={true}
                 updateBtnAction={this.updateProject}
                 isAdmin={this.state.isAdmin} /> */}
                  {this.state.isAdmin &&
                     <div>
                      <br/>   
                       <span class="btn btn-primary float-right btn-danger" style={{marginRight:'50px'}} onClick={this.deleteProject} >Delete Project</span>
                      <span class="btn btn-primary float-right" style={{marginRight:'20px'}} onClick={this.updateProject} >Update Project</span>
                     </div>
                     
                     } 
            
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="project_img_box">
                                <div className="project_img" style={{backgroundImage: `url(${projectImage})`}}></div>
                                <ul>
                                    <li>
                                        <h4>{projectTarget}</h4>
                                        <h5>PROJECT TARGET</h5>
                                    </li>
                                    <li>
                                        <h4>{projectRaise}</h4>
                                        <h5>DONATIONS</h5>
                                    </li>
                                    <li>
                                        <div className="donated">
                                            <h5>DONATED <span>
                                                {percentage.toFixed(0)}%</span></h5>
                                            <Progress value={percentage.toFixed(0)} />
                                            <span className="donated_line"></span>
                                        </div>
                                        <h5>Rs. {projectTarget-projectRaise} To Go</h5>
                                    </li>
                                    <li>
                                    {/* <span class="btn btn-warning btn-info" style={{marginRight:'20px'}} onClick={this.updateProject} >Contribute Now!</span> */}
                                        <Link to="/donate" className="btn btn-light">Contribute Now!</Link>
                                        </li>
                                </ul>
                            </div>

                            <div className="project_content">
                                <h2>{projectTitle}</h2>
                                <ul>
                                    <li>On {date.toDateString()}</li>
                                    <li><Link to="/donate">DONATE</Link></li>
                                    <li><Link to="/projects">VIEW MORE PROJECTS</Link></li>
                                </ul>

                                <p>
                                {projectDescription}
                                </p>
                                <br/>  <br/>  <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    console.log("home state",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{UserInfoAction,DeleteProjects})(ProjectDetail);

