import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import {UserInfoAction,UpdateProfile} from '../../actions/AuthActions'
import Success from '../reusable/Success';
import { Loader } from '../SvgIcon';
import _ from 'lodash'




class EditProfile extends Component {
    constructor(props){
        super(props);

        this.state = {
            montsNames: ['Jan','feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            days: [],
            firstName: '',
            lastName: '',
            email: '',
            address: '',
            contactNumber: '',
            profession: '',
            gender: '',
            month: '',
            day: '',
            year: '',
            password: '',
            passwordAgain: '',
            fNameErrorMsg:'',
            lNameErrorMsg:'',
            contactErrorMsg:'',
            userImage: require('../../assets/user_placeholder.png'),
            uploadProfilePic:'',
            dob:'',
            openPopup:false,
            token:'',
            genderData:null,
            isSuccess:false,
            loaderOpen:false
        }
    }


    async componentWillMount(){
        console.log('component')
        let token = await localStorage.getItem('accessToken');
        this.setState({token:token})
        console.log("tokennn",typeof token,"---",token)
        let data={
           'userId':" "
        }
        if(token){
            this.props.UserInfoAction(data,token).then(response => {
                let birthDate = response.responseData.dob != null && new Date(response.responseData.dob);
                console.log("profile image path",response.responseData.userProfiles,birthDate,"res",response)
                if (response.statusCode == 1) {
                    let fName=response.responseData.firstName.slice(0,1).toUpperCase()+response.responseData.firstName.slice(1, response.responseData.firstName.length)
                    let lName = response.responseData.lastName.slice(0,1).toUpperCase()+response.responseData.lastName.slice(1, response.responseData.lastName.length)
                    this.setState({
                    firstName:fName,
                    lastName:lName,
                    contactNumber:response.responseData.contactNumber,
                    email:response.responseData.email,
                    profession:response.responseData.profession,
                    userImage:_.isEmpty(response.responseData.userProfiles)?require('../../assets/user_placeholder.png'):response.responseData.userProfiles,
                    dob:birthDate,
                    address:response.responseData.address
                    })
               if(response.responseData.gender == 1){
                this.setState({
                    gender:'male',
                    genderData:1
                })
            }else if (response.responseData.gender == 2){
                this.setState({
                    gender:'female',
                    genderData:2
                })
            }else  if(response.responseData.gender == 3){
                this.setState({
                    gender:'Other',
                    genderData:3
                })
            }else{

            }
                    
       }else {
                     
        }}).catch(e => {
            console.log(e)
         })
            
     }}

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleUserImageUpload = event => {
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.addEventListener("load", () => {
            this.setState({userImage: reader.result})
        }, false);
        if (file) {
            reader.readAsDataURL(file)
        }  
         this.setState({uploadProfilePic:file},function(){
            console.log("pathhhh",this.state.uploadProfilePic,"---",file)

        })
   }


    


    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        const regx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        let validate = true;
        let {firstName, lastName, email,password,contactErrorMsg,passwordAgain,contactNumber} = this.state
        let validateFirstName = nameRegx.test(String(this.state.fName).toLowerCase());
        let validateLastName = nameRegx.test(String(this.state.lName).toLowerCase());

        
  

        // First Name Validate

        if ((firstName === '' || firstName === undefined)) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter first name'})
        } else if (!validateFirstName) {
            validate = false;
            this.setState({fNameErrorMsg: 'Please enter a valid first name'})
        } else {
            this.setState({fNameErrorMsg: ''})
        }


        // Last Name Validate

        if (lastName === '' || lastName === undefined) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter last name'})
        } else if (!validateLastName) {
            validate = false;
            this.setState({lNameErrorMsg: 'Please enter valid last name'})
        } else {
            this.setState({lNameErrorMsg: ''})
        }

         //phone validate
        
         if(contactNumber === '' || contactNumber === undefined){
             console.log("conatct namuber")
             validate = false;
            this.setState({contactErrorMsg: 'Please enter contact number'})
        }else if ((contactNumber.length < 10)){
            validate = false;
            this.setState({contactErrorMsg: 'Enter a valid contact number'})
        }else{
            this.setState({contactErrorMsg:''})
        }
         return validate
    }

        handleSubmit = e => {
        e.preventDefault();
        console.log(this.state,"123333")
        const {token,email,firstName,lastName,address,profession,uploadProfilePic,contactNumber,dob} = this.state;
        let genderData = this.state.gender == "male" ? 1 : this.state.gender == "female" ? 2 : 3
        console.log(genderData,"ggggggg",this.state.uploadProfilePic)
        if (this.handleValidetion()) {
            this.setState({loaderOpen:true})
            
           

            let object = new FormData()
            object.append('userProfiles', uploadProfilePic);
            object.append('email', email);
            object.append('firstName', firstName);
            object.append('lastName', lastName);
            object.append('gender', genderData);
            object.append('address', address);
            object.append('profession', profession);
            object.append('contactNumber',contactNumber);
            object.append('dob', dob);


            console.log("user data info",object)
            this.props.UpdateProfile(object).then(response => {
                console.log(response,"user deatils response 444")
                 if (response =="Success") {
                    this.setState({loaderOpen:false})
                   this.setState({
                       isSuccess:true
                   })
                    
                }else {
                     
                 }
             }).catch(e => {
         })

            


        } else {

        }
    }
    closeModal = () =>{this.setState({openPopup: false});}

    handleDatePicker = e => this.setState({dob: e})

    setGender=(e)=> {
   //  e.preventDefault();
     console.log(e.target.value,"asdasdasdasdewrtegbrfedfregrewfrgewqfg")
        this.setState({
          gender: e.target.value
        })
       
      }

    render(){
        document.body.classList.add('cm_home_page');

        let {
            montsNames,
            days,
            firstName,
            lastName,
            email,
            address,
            contactNumber,
            profession,
            gender,
            month,
            userImage,
            dob,
            contactErrorMsg,
            fNameErrorMsg,
            lNameErrorMsg,
            genderData,
            loaderOpen
        } = this.state;

        var cuurentDate = new Date()
        var minDate = new Date(cuurentDate.getFullYear() - 100, 0, 1)
        var maxDate = new Date(cuurentDate.getFullYear() -10, cuurentDate.getMonth(), cuurentDate.getDate())
        var maleCheck =  genderData!== null ? (genderData == 2 || genderData == 3 ? false :true) :null
        var femaleCheck =  genderData!== null ? (genderData == 1 || genderData == 3 ? false :true) :null
        var otherCheck =  genderData!== null ? (genderData == 1 || genderData == 2 ? false :true) :null


        console.log("gender",genderData,"----","male",maleCheck,"femaleCheck",femaleCheck,"other",otherCheck)

        return(
            <div className="other_page">
                {loaderOpen && <Loader/>}
                {!this.state.isSuccess &&
                <form onSubmit={this.handleSubmit} className="mt-5 mb-5">
                    <div className="container">
                        <div className="row">

                            <div className="col-sm-12 mt-5 mb-5 p-5 cm_file_upload_wrapper">
                                <span
                                    style={{backgroundImage: `url(${userImage})`}}
                                    className="file_upload_img"
                                >
                                    <input
                                        type="file"
                                        className="cm_upload_input"
                                        id="userImage"
                                        name="userImage"
                                        accept="image/jpeg,image/jpg,image/png,"
                                        onChange={this.handleUserImageUpload}  
                                    />
                                    <span className="cm_upload_text">Edit Photo</span>
                                </span>
                                
                            </div>
                        
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">First Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    name="firstName"   
                                    value={firstName}
                                    maxLength={25}
                                    onChange={this.handleChange}  
                                />
                                    {fNameErrorMsg ?
                                <div class="input_error_msg">{fNameErrorMsg}</div> : null}

                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Last Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    name="lastName"
                                    value={lastName}
                                    maxLength={25}
                                    onChange={this.handleChange}  
                                />
                                    {lNameErrorMsg ?
                                <div class="input_error_msg">{lNameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email" 
                                    maxLength={25}
                                    value={email}
                                    //onChange={this.handleChange}    
                                />
                            </div>

                            <div className="form-group col-lg-8 col-md-6">
                                <label htmlFor="name">Full Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    name="address" 
                                    value={address}
                                    maxLength={75}
                                    onChange={this.handleChange}   
                                />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Contact Number*</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={contactNumber}
                                    maxLength="10"
                                    onChange={this.handleChange}  
                                />
                                    {contactErrorMsg ?
                                <div class="input_error_msg">{contactErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Profession</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="profession"
                                    name="profession" 
                                    value={profession}
                                    maxLength={25}
                                    onChange={this.handleChange}    
                                />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="gender">Gender</label>
                                    <div className="cm_space">
                                    <span className="cm_radio_wrapper">
                                        Male
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="male"
                                            defaultChecked={maleCheck}
                                            onClick={this.setGender}
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>

                                    <span className="cm_radio_wrapper">
                                    Female
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="female"
                                            defaultChecked = {femaleCheck }
                                            onClick={this.setGender}
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>

                                    <span className="cm_radio_wrapper">
                                    Other
                                        <input
                                            type="radio"
                                            className="cm_radio_input"
                                            id="gender"
                                            name="gender"
                                            value="other"
                                            defaultChecked= {otherCheck}
                                            onClick={this.setGender}
                                        />
                                        <span className="cm_radio_box"></span>    
                                    </span>
                                </div>
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="profession">Date of Birth</label>
                                <DatePicker
                                    selected={dob}
                                    onChange={this.handleDatePicker}
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    dateFormat="dd/MM/yyyy"
                                    scrollableYearDropdown
                                    placeholderText="DD/MM/YYYY"
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    yearDropdownItemNumber={15}
                                    className="form-control d-block"
                                    name="dob"
                                />
                            </div>
                          
                        
                        </div>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                    </div>
                </form>}
                {this.state.isSuccess &&               
                  <Success btnTitle={'Go To My Profile'} 
                  title={'Your Profile has been successfully updated.'}
                  btnAction={()=>this.props.history.push('/profile')}/>}
            
            </div>
        )
    }

}

const mapStateToProps = state => {
    console.log("home state",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{UserInfoAction,UpdateProfile})(EditProfile);

