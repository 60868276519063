import React from 'react'
import { NavLink,Link } from 'react-router-dom'
import { Redirect} from 'react-router-dom'
import {connect} from "react-redux";
import {UserInfoAction,UserLogout} from '../../../actions/AuthActions'
import _ from 'lodash'
import {history} from '../../../App'

const mobileOpen = () => document.body.classList.add('open_menu');

class Header extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            menuOpen :false,
            isLoggIn: false,
            userName:'',
            isRedirect:false
        }
        //console.log("kjahdjkbkasndknaksdnkankasdakjndkajsd",history);
    }
     async componentWillMount(){
        console.log('component')
        let token = await localStorage.getItem('accessToken');
        console.log("tokennn",typeof token,"---",token)
        let data={
           'userId':" "
        }
        if(token){
            this.props.UserInfoAction(data,token).then(response => {
                console.log(response,"user deatils response 444")
                 if (response.statusCode == 1) {
                    this.setState({
                        isLoggIn:true,
                        userName:response.responseData.firstName+" "+response.responseData.lastName
                    })
                    
                }else {
                     
                 }
             }).catch(e => {
         })
            
     }}

     UNSAFE_componentWillReceiveProps(props) {
             //console.log("ppppp",props.userDetails.accessToken)
             console.log('user name',props.userDetails.userProfile.firstName)
             let token = props.userDetails.accessToken;
             let name = props.userDetails.userProfile.firstName +" "+props.userDetails.userProfile.lastName
             let self = this;
             if(token){
                 this.setState({
                 isLoggIn:true,
                 userName:name

              })
          }
    }  
    handleLogOut = async () => {
        let self = this;
        //console.log("skjndkajsndjkndkjas######",history);
        console.log("logout")
        this.setState({loaderOpen: true})
        let token = await localStorage.getItem('accessToken')
        console.log("tokeeee",token)
        let data ={
            accessToken:token
        }
        this.props.UserLogout(token)
            .then(res => {
                console.log(res)
                if(res.data.statusCode == 1){
                    localStorage.removeItem('accessToken');
                    console.log("logout suceessfully!")
                    window.location.reload();
                    history.push('/login');
                }
                
                
            })
    } 


    

   render(){
        //const [menuOpen, setMenuOpen] = useState(false);


     
        let { isLoggIn } = this.state;

       return(
        <div className={`header_wrapper ${this.state.menuOpen ? 'open' : ''}`}>
            <header>
                <div className="container">
                <div className="cm_logo">
                        <a href="/">
                            <img src={require('../../../assets/logo.png')} alt="" />
                        </a>
                    </div>
                    <div onClick={() => this.setState({menuOpen:!this.state.menuOpen})} className="mobile_triger">
                        <svg viewBox="0 0 612 612"><path fill="#fff" d="M0,97.92v24.48h612V97.92H0z M0,318.24h612v-24.48H0V318.24z M0,514.08h612V489.6H0V514.08z"/></svg>
                    </div>

                    <div className="cm_menu">
                        <ul>
                            <li><NavLink exact to='/'>Home</NavLink></li>&nbsp;&nbsp;
                            <li><NavLink to='/projects'>Our Projects</NavLink></li>&nbsp;&nbsp;
                            <li><NavLink to='/contact-us'>Contact Us</NavLink></li>&nbsp;&nbsp;
                            <li className="cm_auth_link">
                                {this.state.isLoggIn == true &&
                                <NavLink>
                                     <img src={require('../../../assets/userIcon.png')} alt="" />
                                     {this.state.userName}
                                     </NavLink>}
                                {this.state.isLoggIn == false &&
                                 <NavLink>
                                 <NavLink to="/login">Login   /</NavLink>&nbsp;&nbsp;
                                 <NavLink to="/registration">Register</NavLink>&nbsp;&nbsp;&nbsp;
                                 </NavLink>
                                 
                                 }

                                 {
                                     isLoggIn ? (
                                        <ul>
                                            <li><NavLink to="/profile">My Profile</NavLink></li>
                                              <li onClick={this.handleLogOut}>
                                                 <NavLink>Logout</NavLink> 
                                               </li>
                                           
                                        </ul>
                                     ) : null
                                 }
                        </li>
                            <li><NavLink to="/donate" className="btn btn-primary">Contribute Now!</NavLink></li>
                        </ul>
                    </div>


                </div>
            </header>
        </div>
    )}
}
const mapStateToProps = state => {
    console.log("123456",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{UserInfoAction,UserLogout})(Header);

