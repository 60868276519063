import React, { Component } from 'react'

class ContactUs extends Component {

    constructor(props){
        super(props);
        console.log("propsvlaue",props)
        this.state = {
            name: '',
            email: '',
            infoText: '',
            phone:'',
            messageText:'',
            nameErrorMsg:'',
            emailErrorMsg:'',
            isSuccess:false

        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = e => {
        const {propsValue} = this.props;
        e.preventDefault();
        console.log(this.state)
        if (this.handleValidetion()) {
            this.setState({
                isSuccess:true
            })
            
        }else {

        }
    }

    handleValidetion = () => {

        const nameRegx = /^[a-zA-Z ]+$/;
        let validate = true;
        let {name, email} = this.state
        let validateFirstName = nameRegx.test(String(this.state.name).toLowerCase());
        var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        //  Name Validate

        if ((name === '' || name === undefined)) {
            validate = false;
            this.setState({nameErrorMsg: 'Please enter name'})
        } else if (!validateFirstName) {
            validate = false;
            this.setState({nameErrorMsg: 'Please enter a valid name'})
        } else {
            this.setState({nameErrorMsg: ''})
        }

        // Email Validate

        if (email === '' || email === undefined) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter email address'})
        } else if (!emailRegex.test(email)) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address'})
        } else {
            this.setState({emailErrorMsg: ''})
        }

        return validate
    }

    render(){
        let { email,name,emailErrorMsg,nameErrorMsg,isSuccess } = this.state;
        return(
            <div className="section contact_us_form_wrapper">
                {!isSuccess &&
                <form onSubmit={this.handleSubmit}>
                    <div className="container">
                        <h3>Let’s Talk for Cooperation!</h3>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="name">Name*</label>
                                <input type="text"
                                       className="form-control"
                                       id="name"
                                       name="name"
                                       value={name}
                                       onChange={this.handleChange} />
                                {nameErrorMsg ?<div class="input_error_msg">{nameErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="email">Email*</label>
                                <input type="email"
                                       className="form-control"
                                       id="email"
                                       name="email"
                                       value={email}
                                       onChange={this.handleChange} />
                                {emailErrorMsg ? <div class="input_error_msg">{emailErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="number">Contact Number</label>
                                <input type="text" className="form-control" id="number" name="number" />
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">How did you hear about us?</label>
                                <input type="text" className="form-control" id="" name="" />
                            </div>

                            <div className="form-group col-md-12">
                                <label htmlFor="message">Message</label>
                                <textarea class="form-control" id="message" name="message" rows="3"></textarea>
                            </div>

                            {/* <div className="form-group align-self-center col-md-7">
                                <div className="row">
                                    <div className="col-8 align-self-center">Captcha, What is 3 + 5 =</div>
                                    <div className="col-4 align-self-center"><input type="text" className="form-control" id="captcha" name="captcha" placeholder="Answer" /></div>
                                </div>
                            </div> */}
                            <div className="form-group align-self-center col-md-5 text-right">
                                <button className="btn btn-primary">Send!</button>
                            </div>

                        </div>
                    </div>
                </form>}
                {isSuccess &&
                 <div className="success_wrapper">
                      <div className="">
                      <div className="container text-center">
                      <div className="row justify-content-center pb-2">
                        <div className="col-md-5 order-2 align-self-center">
                            <img className="success_logo_img" src={require('../../assets/successLogo.png')} />
                        </div>
                </div>
                 <div className="container">
                    <h2 className="gray_text">Kudos to your thought, you are one step closer for being a part of this beautiful journey.</h2>
                    <h4 className="gray_text">Our team member will contact you soon!</h4>
                    <br/>
                     <div className="mt-3 text-center">
                        <button type="submit" className="btn btn-primary"
                        >Go To Home</button></div>
                     </div>
                    <br/><br/>
                    </div>
                </div>
               </div>}
            </div>
        )
    }
}

export default ContactUs;