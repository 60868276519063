import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import {connect} from "react-redux";
import { Checkicon } from  '../SvgIcon';
// import {NotificationContainer, NotificationManager} from 'react-notifications';

class Payment extends Component {


    handleTransaction=()=>{
        console.log("transaction")
        this.props.history.push('/addCard')
    }
    
    render() {
        document.body.classList.add('cm_home_page');
        return(
            <React.Fragment>
            <div className="other_page fix-footer"><br/><br/>
            <div className="container">
         
                    <div className="dashboard-box">
                    <h4 className="graycolorh4">Payment Methods</h4><br/>
                        <div className="table-responsive">
                                <table className="table cm_table payment-table">
                                    <tr>
                                        <td className="text-align: center;"><img src={require('../../assets/paypal.png')} className="img-responsive" /></td>
                                        <td>Paypal</td>
                                        <td><span className="gray_tick active" ><Checkicon/></span>
                                     </td>
                                    </tr>
                                    <tr>
                                        <td className="text-align: center;"><img src={require('../../assets/Credit Card.png')} className="img-responsive" /></td>
                                        <td>Debit  Card</td>
                                        <td><span className="gray_tick"><Checkicon/></span></td>
                                    </tr>
                                    <tr>
                                        <td className="text-align: center;"><img src={require('../../assets/Cebit Card.png')} className="img-responsive" /></td>
                                        <td>Credit Card</td>
                                        <td><span className="gray_tick"><Checkicon/></span></td>
                                    </tr>
                                 </table>
                                <br/>
                                <div class="text-center">
                                    <p class="btn btn-primary" onClick={this.handleTransaction}>PROCEED</p></div>
                        </div>
                    </div>
        </div>
        </div>
        </React.Fragment>
        )
    }
}


export default Payment;

