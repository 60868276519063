import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Popup from "reactjs-popup";
import {connect} from "react-redux";
import {UpdatePassword} from '../../actions/AuthActions';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import _ from'lodash';
import Success from '../reusable/Success';
import { Loader } from '../SvgIcon';

class ChangePassword extends Component {
    constructor(props){
        super(props);
        this.state={
            oldPassword:'',
            newPassword:'',
            passwordAgain:'',
            oldPasswordErrorMsg:'',
            newPasswordErrorMsg:'',
            passwordAgainErrorMsg:'',
            openPopup:false,
            isSuccess:false,
            token:'',
            loaderOpen:false
        }
    }

    async componentWillMount(){
        console.log('component')
        let token = await localStorage.getItem('accessToken');
        this.setState({token:token})
        console.log("tokeennnnn",this.state.token)
    }   

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleValidetion = () => {
        let validate = true;
           
            let {oldPassword,newPassword,passwordAgain,oldPasswordErrorMsg,newPasswordErrorMsg,passwordAgainErrorMsg} = this.state;
       
            // oldPassword Validate
            
        if ((oldPassword === '' || oldPassword === undefined)) {
            validate = false;
            this.setState({oldPasswordErrorMsg: 'Please enter old password.'})
        } else if ((oldPassword.length <= 5)) {
            validate = false;
            this.setState({oldPasswordErrorMsg: 'Please enter valid old password.'})
        } else {
            this.setState({oldPasswordErrorMsg: ''})
        }

       
     // newPassword Validate

        if ((newPassword === '' || newPassword === undefined)) {
            validate = false;
            this.setState({newPasswordErrorMsg: 'Please enter new password.'})
        } else if ((newPassword.length <= 5)) {
            validate = false;
            this.setState({newPasswordErrorMsg: 'Minimum 6 letter password require.'})
        } else {
            this.setState({newPasswordErrorMsg: ''})
        }

      // newPassword Validate

      if ((passwordAgain === '' || passwordAgain === undefined)) {
        validate = false;
        this.setState({passwordAgainErrorMsg: 'Please enter password again.'})
    } else if ((passwordAgain.length <= 5)) {
        validate = false;
        this.setState({passwordAgainErrorMsg: 'Minimum 6 letter password require'})
    } else if((newPassword != passwordAgain)) {
        validate = false;
        this.setState({passwordAgainErrorMsg: 'New Password & Password again are not same.'})

    }else{
        this.setState({passwordAgainErrorMsg: ''})
       
    }

       return validate
    }
    closeModal = () =>{this.setState({openPopup: false});}

    createNotification = (type) => {
        console.log("ggggg")
        switch (type) {
            case 'info':
              NotificationManager.info('Info message');
              break;
            case 'success':
              NotificationManager.success('Success message', 'Title here');
              break;
            case 'warning':
              NotificationManager.warning('Invalid email or password');
              break;
            case 'error':
              NotificationManager.error('Old password is not correct. Please try again.');
              break;
          }
        
      };

    handleSubmit = e => {
        e.preventDefault();
        console.log(this.state)
        const {token} = this.state;
        
        
        if (this.handleValidetion()) {
            this.setState({loaderOpen:true})
            let data = {
               'oldPassword':this.state.oldPassword,
               'newPassword':this.state.newPassword
            }
            this.props.UpdatePassword(data,token).then(response => {
                console.log(response,"update password response")
                 if (response =="Success") {
                    this.setState({
                        isSuccess:true,loaderOpen:false
                    })
                 }else{
                      this.createNotification('error') 
                       this.setState({
                        loaderOpen:false
                    })
                     }
             }).catch(e => {
                 
               this.setState({loaderOpen: false, responseError: "Something went wrong"})
         })

        } else {

        }
    }
    


    render(){
        document.body.classList.add('cm_home_page');
        let {oldPassword,newPassword,passwordAgain,loaderOpen,
            oldPasswordErrorMsg,newPasswordErrorMsg,passwordAgainErrorMsg} = this.state;
        return(
            <div className="other_page fix-footer">
                {loaderOpen && <Loader/>}
                 <NotificationContainer/>
                { !this.state.isSuccess &&
              <div className="container">
                    <div className="row mt-5 mb-5">
                        <div className="col-md-8 mt-5">
                            <h2>Change Password</h2>
                        </div>
                        <div className="col-md-4 mt-5 text-right">
                            <Link to="/profile" className="btn btn-primary">My Profile</Link>
                        </div>
                    </div>
                </div>}
                { !this.state.isSuccess &&

                <form onSubmit={this.handleSubmit} className="mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Enter Old Password*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="oldPassword*"
                                    name="oldPassword" 
                                    placeholder="******" 
                                    value={oldPassword}
                                    onChange={this.handleChange}   
                                />
                                     {oldPasswordErrorMsg ?
                                    <div class="input_error_msg">{oldPasswordErrorMsg}</div> : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Enter New Password*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="newPassword"
                                    name="newPassword"
                                    placeholder="******"
                                    value={newPassword}
                                    onChange={this.handleChange}  
                                      
                                />
                                   {newPasswordErrorMsg ?
                                    <div class="input_error_msg">{newPasswordErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                                <label htmlFor="name">Enter New Password Again*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordAgain"
                                    name="passwordAgain"
                                    placeholder="******"
                                    value={passwordAgain}
                                    onChange={this.handleChange}    
                                />
                                   {passwordAgainErrorMsg ?
                                    <div class="input_error_msg">{passwordAgainErrorMsg}</div> : null}
                            </div>
                        </div>
                       <button type="submit" className="btn btn-primary">Change Password</button>
                    </div>
                </form>}
                {this.state.isSuccess &&  <Success btnTitle={'Go To My Profile'} 
                  title={'Your Password has been successfully changed.'}
                  btnAction={()=>this.props.history.push('/profile')}/>}
            
             
            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log("change password screen",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{UpdatePassword})(ChangePassword);

