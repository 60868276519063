import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

export const Slider = () => {
    return(
        <Carousel
            showThumbs={false}
            showArrows={false}
            autoPlay={true}
            infiniteLoop={true}>
            <div style={{background: `url(${require('../../../assets/slider1.jpg')})`}}></div>
            <div style={{background: `url(${require('../../../assets/slider2.jpg')})`}}></div>
            <div style={{background: `url(${require('../../../assets/slider3.jpg')})`}}></div>
            <div style={{background: `url(${require('../../../assets/slider4.jpg')})`}}></div>
            <div style={{background: `url(${require('../../../assets/slider5.jpg')})`}}></div>
            <div style={{background: `url(${require('../../../assets/slider6.jpg')})`}}></div>
        </Carousel>
    )
}


export const SliderBox = () => {
    return(
        <div className="slider_content">
           <div className="slider_text">
                <h1>They are the Future</h1>
                <h3>Shining India is only possible with educated India—Ek Vichaar wants to be a wave of that shine</h3>
                 <h4>To make realize to the nation that quality primary education is not an amenity or luxury but a necessity</h4>
             </div>
        
            <ul>
                <a href="/donate">
                <li className="color_bg_1">
                    <img src={require('../../../assets/donate-icon.png')} />
                    <p>Donate</p>
                </li>
                </a>
                <a href="/projects">
                <li className="color_bg_2">
                    <img src={require('../../../assets/school-icon.png')} />
                    <p>Project</p>
                </li></a>
                <a href="/members">
                <li className="color_bg_3">
                    <img src={require('../../../assets/members-icon.png')} />
                    <p>Members</p>
                </li></a>
                <a href="/team">
                <li className="color_bg_4">
                    <img src={require('../../../assets/team-icon.png')} />
                    <p>The Team</p>
                </li></a>
            </ul>
        </div>
    )
}
