import React, { Component } from 'react'
import Success from '../reusable/Success';
import {Donated_Money} from '../../actions/AuthActions'
import {connect} from "react-redux";
import { Loader } from '../SvgIcon';
import InputMask from 'react-input-mask';
import {NotificationContainer, NotificationManager} from 'react-notifications';

class AddCard extends Component {


    constructor(props){
        super(props)
        //console.log("props card",props)
        this.state ={
            isSuccess:false,
            cardNum:'',
            cardHolder:'',
            cardCVC:'',
            cardExpiry:'',
            cardNumErrorMsg:'',
            cardHolderErrorMsg:'',
            cardCVCErrorMsg:'',
            cardExpiryErrorMsg:'',
            email:props.location.state != null && props.location.state.email,
            fName:props.location.state != null && props.location.state.fName,
            lName: props.location.state != null && props.location.state.lName,
            donateAmount:props.location.state != null && props.location.state.donateAmount,
            message:props.location.state != null && props.location.state.message,
            projectId:props.location.state != null && props.location.state.project,
            isAnonymousCheck:props.location.state != null && props.location.state.isAnonymous,
            currency:props.location.state != null && props.location.state.currency,
            address:props.location.state != null && props.location.state.address,
            pinCode:props.location.state != null && props.location.state.pinCode,
            loaderOpen:false
        }

       
    }

    createNotification = (type) => {
        switch (type) {
            case 'invalid expiry date':
              NotificationManager.error('Your cards expiration month is invalid.');
              break;
            case 'success':
              NotificationManager.success('Success message', 'Title here');
              break;
            case 'warning':
              NotificationManager.warning('Invalid email or password');
              break;
            case 'invalid card':
              NotificationManager.error('Your card number is incorrect.');
              break;
          }
        
      };
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handlePayment= e =>{
        e.preventDefault();
        console.log("paymentssss",this.state)
        const {cardNum,cardCVC,donateAmount,message,fName,lName,email,currency,
            projectId,cardExpiry,isAnonymousCheck,address,pinCode} = this.state;
       
        // console.log("000",cardExpiry,"00000",cardExpiry.substr(0,2),cardExpiry.substr(3.5))

        


        if (this.handleValidetion()) {
            this.setState({loaderOpen:true})
           let data ={
                'currency':currency,
                'cardNumber':cardNum,
                'expMonth':cardExpiry.substr(0,2),
                'expYear':cardExpiry.substr(3,5),
                'cvc':cardCVC,
                'name':fName,
                'email':email,
                'amount':donateAmount,
                'firstName':fName ,
                'lastName':lName,
                'message':message ,
                'projectId':projectId,
                'isAnonymousCheck':isAnonymousCheck,
                address:address,
                pinCode:pinCode,
            }
            
            this.props.Donated_Money(data).then(response=>{
                console.log("data payment",response)
                if(response.data.statusCode === 1){
                    this.setState({
                        isSuccess:true,loaderOpen:false
                    })
                }else if(response.data.statusCode === 0){
                        if(response.data.error.errors.message != "Your card number is incorrect."){
                              this.createNotification('invalid expiry date') 
                           
                        }else if(response.data.error.errors.message == "Your card number is incorrect."){
                              this.createNotification('invalid card') 
                        }else{

                        }
                    this.setState({
                       loaderOpen:false
                    })
                  // alert(response.data.error.errors.stack)
                }
             }).catch(e=>{
                 console.log(e)
             })
          
           

        }else{

        }
       

    }

    handleValidetion = () => {

        let validate = true;
        const {cardNum,cardHolder,cardCVC,cardExpiry} = this.state;
       
    


        // card number Validate

        if (!cardNum) {
            validate = false;
            this.setState({cardNumErrorMsg: 'enter card number'})
        }else{
           this.setState({cardNumErrorMsg: ''})
           
        }

        if (!cardHolder) {
            validate = false;
            this.setState({cardHolderErrorMsg: 'enter card holder name'})
         } else {
            this.setState({cardHolderErrorMsg: ''})
        }

        if (!cardCVC) {
            validate = false;
            this.setState({cardCVCErrorMsg: 'enter card cvc'})
         } else {
            this.setState({cardCVCErrorMsg: ''})
        }
        if (!cardExpiry) {
            validate = false;
            this.setState({cardExpiryErrorMsg: 'enter card expiry date'})
         } else {
            this.setState({cardExpiryErrorMsg: ''})
        }

      return validate

    }


 

    
    render() {
        document.body.classList.add('cm_home_page');
        const { cardNum,cardNumErrorMsg,cardHolder,cardHolderErrorMsg,cardCVC,loaderOpen,cardCVCErrorMsg,cardExpiry,cardExpiryErrorMsg} = this.state;
        return(
            <div>

                 {
                    loaderOpen ? <Loader/> :null
                }
                  <NotificationContainer/>
                {!this.state.isSuccess &&
                <div className="section contact_us_form_wrapper other_page fix-footer">
                
               
               <form >
                    <div className="container ">
                        <h3>Card Details</h3>
                        <br/>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="name">Card Number*</label>

                                <InputMask mask="9999 9999 9999 9999" value={cardNum}
                                               onChange={this.handleChange}>
                                        {(inputProps) => <input type="text"
                                                                class="form-control "
                                                                placeholder="XXXX XXXX XXXX XXXX"
                                                                name="cardNum"
                                                                value={cardNum}
                                                                onChange={this.handleChange}
                                        />
                                        
                                        }
                                        
                                    </InputMask>
                                {/* <input type="number"
                                       className="form-control"
                                       id="name"
                                       name="cardNum"
                                       value={cardNum}
                                       placeholder={'Card Number'}
                                       maxLength={16}
                                       onKeyPress="if(this.value.length==10) return false;"
                                       onChange={this.handleChange}
                                /> */}
                                       {cardNumErrorMsg ? <div class="input_error_msg">{cardNumErrorMsg}</div> : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="text">Cardholder Name*</label>
                                
                                <input type="text"
                                         id="name"
                                         name="cardHolder"
                                         value={cardHolder}
                                         maxLength={25}
                                         placeholder={'Cardholder name'}
                                         onChange={this.handleChange}
                                       className="form-control"
                                      />

                                 {cardHolderErrorMsg ? <div class="input_error_msg">{cardHolderErrorMsg}</div> : null}
                                
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="message">CVC*</label>
                                <InputMask mask="999" value={cardCVC}
                                               onChange={this.handleChange}>
                                        {(inputProps) => <input type="text"
                                                                class="form-control "
                                                                placeholder="XXX"
                                                                name="cardCVC"
                                                                value={cardCVC}
                                                                onChange={this.handleChange}
                                        />
                                        
                                        }
                                        
                                    </InputMask>
                                {/* <input type="number" class="form-control" 
                                maxLength={3}
                                id="message" name="cardCVC" placeholder={'***'}  value={cardCVC} onChange={this.handleChange}
                                rows="3"/> */}
                                  {cardCVCErrorMsg ? <div class="input_error_msg">{cardCVCErrorMsg}</div> : null}
                            </div>
                           


                            <div className="form-group col-md-6">
                                <label htmlFor="number">Expiration Date (MM/YY)*</label>
                                <InputMask mask="99/99" value={cardExpiry}
                                               onChange={this.handleChange}>
                                        {(inputProps) => <input type="text"
                                                                class="form-control "
                                                                placeholder="MM / YY"
                                                                name="cardExpiry"
                                                                value={cardExpiry}
                                                                onChange={this.handleChange}
                                        />
                                        
                                        }
                                        
                                    </InputMask>
                                  {cardExpiryErrorMsg ? <div class="input_error_msg">{cardExpiryErrorMsg}</div> : null}
                            </div>
                         

                          
                          <div className="form-group align-self-center col-md-12 float-right">
                          <br/>
                                <button onClick={this.handlePayment}
                                className="btn btn-primary float-right">PROCEED</button>
                            </div>
                      </div>
                    </div>
                </form>
                </div>}
                {this.state.isSuccess &&
                  <Success btnTitle={'Contributers'} 
                  title={'Team Ek Vichaar thanking you for your contribution. God bless you!'}
                  btnAction={()=>this.props.history.push('/donators')}/>
                }
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log("123456",state)
    return {};
};

export default connect(mapStateToProps,{Donated_Money})(AddCard);




