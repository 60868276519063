import React from 'react';
import { Link } from 'react-router-dom'

const Success=(props)=>{
    
    return(
        <div className="success_wrapper fix-footer">
           <div className="">
             <div className="container text-center">
                    <div className="row justify-content-center pb-2">
                        <div className="col-md-5 order-2 align-self-center">
                            <img className="success_logo_img" src={require('../../assets/successLogo.png')} />
                        </div>
                </div>
                               
                 <div className="container">
                    <h3 className="gray_text">{props.title}</h3>
                    <div className="mt-5 text-center">
                        <button type="submit" className="btn btn-primary"  
                         onClick={()=>props.btnAction()}>&nbsp;&nbsp;&nbsp;{props.btnTitle}&nbsp;&nbsp;&nbsp;</button></div>
                    </div>
                    </div>
                                   
                </div>
        </div>
    )
}

export default Success;