import React from 'react'
import { Slider, SliderBox } from './components/Slider'
import Gallery from 'react-grid-gallery'
import { Link } from 'react-router-dom'
import DonatedSection from '../reusable/DonatedSection'
import OurTeam from '../reusable/OurTeam'
import Testimonial from '../reusable/Testimonial'
import OurImpact from './components/OurImpact'
import {connect} from "react-redux";
import {UserInfoAction,Gallery_Images,GetProjectLists,Total_Donation,
    MemberLists} from '../../actions/AuthActions';
import _ from 'lodash'
import { Loader } from '../SvgIcon';
var projectCount = '0'
var memberCount = '0'
var totalDonation = '0'



const IMAGES = [
    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300

    },

    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300
    },

    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300

    },

    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300
    },

    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300

    },

    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300
    },

    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300

    },

    {
        src: require('../../assets/gallary_1.png'),
        thumbnail: require('../../assets/gallary_1.png'),
        thumbnailWidth: 300,
        thumbnailHeight: 300
    },
]

class Home extends React.Component{

    constructor(props){
        super(props);
        this.state={
            isLoggIn: false,
            projectImgs:[],
            loaderOpen:true,
            projectCount:'',
            imgLists:[]

        }
        this.getGallery();
        this.totalDonation();
    }

    getGallery=()=>{
        console.log("getGallery")
        this.props.Gallery_Images("bbbb").then(res=>{
            console.log("images gallery res",res.data.responseData)
           this.setState({projectImgs:res.data.responseData,loaderOpen:false},function(
 
            ){
               console.log("state value of gallary",this.state.projectImgs[12]) 
            })
          
            this.state.imgLists.push({
                'src':res.data.responseData,
                'thumbnail':res.data.responseData,
                'thumbnailWidth': 300,
                'thumbnailHeight': 300
            })

        }).catch(e=>{
            console.log(e,"images gallery error")
        })
    }
    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(0)) + 'k' : Math.sign(num)*Math.abs(num)
    }
    totalDonation=()=>{
        this.props.Total_Donation().then(res=>{
            console.log(res,"total donation money")
            totalDonation = this.kFormatter(res.Totalamount)
            console.log(totalDonation,"total money")

        }).catch(e=>{
            console.log(e,"09090909")
        })
    }
    async componentWillMount(){
        console.log('component')
        let token = await localStorage.getItem('accessToken');
        console.log("tokennn",typeof token,"---",token)
        let data={
           'userId':" "
        }
        if(token){
            this.props.UserInfoAction(data,token).then(response => {
                console.log(response,"user deatils response 444")
                 if (response.statusCode == 1) {
                    this.setState({
                        isLoggIn:true,
                       
                    })
                    
                }else {
                     
                 }
             }).catch(e => {
         })
            
       }
  
    
    }

     componentWillUpdate(){
         console.log("will update")
         //console.log("ppppp",this.props.userDetails.accessToken)
     }
    

      
    UNSAFE_componentWillReceiveProps(props) {
        //console.log("ppppp",props.userDetails.accessToken)
        if(!_.isEmpty(props.userDetails)){
           let token = props.userDetails.accessToken;
            let self = this;
           if(token){
            console.log("ppppp",props.userDetails.accessToken)
            this.setState({
            isLoggIn:true
         })}
        }  
     }
     componentDidMount(){
        
       let data ={
            'status':1
         }
        this.props.GetProjectLists(data).then(res=>{
            console.log("123456",res)
            projectCount = this.kFormatter(res.length)
            console.log(projectCount,"000009090909")
          })
        let obj ={
            'userRole':1
        }
        this.props.MemberLists(obj).then(res=>{
            console.log(res.data.responseData.length,"45454545")
            memberCount = this.kFormatter(res.data.responseData.length)
       }).catch(e=>{
            console.log(e)
        })  

       

     }
     galleryCard=(props)=>{
        console.log("length images",props.length , props)
        if(props != null){
                if(!_.isEmpty(props) && props != ""){ 
                return(
                    <div className="col-lg-3 col-md-6  blockimg">
                    <img className="response_img" src={props} alt="EkVichaar"/>
                    </div>
                 )}
        
     }
      
    }

      
    

   
    
   
    render(){
        document.body.classList.remove('cm_home_page');
        const {isLoggIn,loaderOpen} = this.state;
    
    return(
        <div className="home_page">

           

            <div className="main_slider">
                <Slider />
                <SliderBox />
            </div>
         <div className="section">
                <div className="container text-center">

                    <div className="row justify-content-center pb-5">
                        <div className="col-md-6 order-2 align-self-center">
                            <img className="img-fluid" src={require('../../assets/our-vision.jpg')} />
                        </div>
                        <div className="col-md-6 align-self-center">
                            <h2>Mission</h2>
                            <p>EK Vichaar is on a mission to make quality education available to all the unprivileged children. With a firm conviction that every single enlighten mind can ignite the urge of education in many minds. 
                                We work to impart education primarily to those who don’t have access to it</p>
                                {
                                     !isLoggIn ? (
                                        <a href="/join-us" className="btn btn-secondary">Join Us Now!</a>
                                     ) : null
                                 }
                           
                        </div>
                     </div>

                    <div className="row justify-content-center pb-5">
                        <div className="col-md-6 align-self-center">
                            <img className="img-fluid" src={require('../../assets/our-vision.jpg')} />
                        </div>
                        <div className="col-md-6 align-self-center">
                            <h2>Process</h2>
                            <p>Starting with a broader foresight but with a practical approach, we will undertake one government undertaking primary school and support it with all the basic to secondary amenities.
                            By the time, if we get more funds through individuals and organizations, Ek Vichaar will include more schools into its reach 
                            </p>
                                {
                                     !isLoggIn ? (
                                        <a href="/join-us" className="btn btn-secondary">Join Us Now!</a>
                                     ) : null
                                 }
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="section light-bg">
                {console.log(this.props.history,'this.props.history')}
                <DonatedSection history={this.props.history}/>
                
            </div>
            <div className="section light-bg testimunial_wrapper">
                <OurTeam /> 
            </div>

            <Testimonial />
            {console.log("member list",memberCount)}
            <OurImpact history={projectCount} member={memberCount} donation={totalDonation} />

            <div className="section image_gallary_wrapper">
                <div className="container">
                    <h3>GALLERY</h3>
                    <p>These smiles are the reason we have taken this initiative in our hands</p>
                    <div className="row">
                      
                    {this.state.projectImgs.slice(0,4).map(imageUrl => this.galleryCard(imageUrl))}
                       
                    </div>     
                        
                  <div className="col btn_wrapper text-center mb-5 mt-5">
                        <Link to="/gallery" className="btn btn-secondary">View More</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}}
const mapStateToProps = state => {
    console.log("home state",state)
    const userDetails = state.authentication.user
    console.log("deatils",userDetails)
    return {userDetails};
};

export default connect(mapStateToProps,{UserInfoAction,
    Gallery_Images,GetProjectLists,MemberLists,Total_Donation})(Home);

